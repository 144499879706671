import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';

import throttle from 'lodash.throttle';

import useAppContext from '../hooks/useAppContext';

import HeaderMenu from './HeaderMenu';

import logo from '../assets/header/logo-white.svg';

type PropType = {};

const Header = (props: PropType) => {
    const { appState } = useAppContext();

    const [hasMobileMenu, setHasMobileMenu] = useState(false);

    const [isNavOpaque, setNavOpaque] = useState(false);

    const toggleMobileMenu = useCallback(() => {
        setHasMobileMenu((prevValue) => !prevValue);
    }, []);

    const throttleScroll = throttle(() => {
        if (window.scrollY > 0) {
            setNavOpaque(true);
        } else {
            setNavOpaque(false);
        }
    }, 100);

    useEffect(() => {
        window.addEventListener('scroll', throttleScroll);

        return () => {
            window.removeEventListener('scroll', throttleScroll);
        };
    }, [throttleScroll]);

    // if page is reloaded from cache, make sure this is in sync
    useEffect(() => {
        if (window.scrollY > 0) {
            setNavOpaque(true);
        } else {
            setNavOpaque(false);
        }
    }, []);

    return (
        <header className={isNavOpaque ? 'nav-active' : undefined}>
            <div className="container position-relative">
                <div className="row align-items-center form-row">
                    <div className="col-lg-2">
                        <div className="logo-box">
                            <a href="#hero">
                                <img src={logo} alt="img" />
                                <span className="brand">{appState.tokenName}</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <HeaderMenu className="menu text-center" />
                    </div>
                    <div className="col-lg-2">
                        <div className="header-btn-box">
                            <a
                                className="button btn-1"
                                href="https://www.pinksale.finance/launchpad/0x19175460BaDE6A4b72dC546BE15A786Ab3B1ecd7?chain=ETH"
                                // href="https://pancakeswap.finance/swap?outputCurrency=0xCD4557BF92B4D92be53561878E989A9C772f071A"
                                target="_blank"
                                rel="noreferrer"
                                // onClick={(e) => {
                                //     e.preventDefault();
                                // }}
                            >
                                Buy
                            </a>

                            {/* <a className="button btn-2" href="#">
                                <span>Connect</span>
                            </a> */}
                        </div>
                    </div>
                    <div
                        className={cx({
                            'hamburger-menu': true,
                            'active': hasMobileMenu,
                        })}
                        onClick={toggleMobileMenu}
                    >
                        <span className="line-top"></span>
                        <span className="line-center"></span>
                        <span className="line-bottom"></span>
                    </div>

                    <div
                        className={cx({
                            'mobile-menu-wrapper': true,
                            'show-menu': hasMobileMenu,
                        })}
                    >
                        <HeaderMenu className="mobile-menu" onClick={toggleMobileMenu} />
                        <div className="header-btn-box off-canvas">
                            <a
                                className="button btn-1"
                                href="https://www.pinksale.finance/launchpad/0x19175460BaDE6A4b72dC546BE15A786Ab3B1ecd7?chain=ETH"
                                // href="https://pancakeswap.finance/swap?outputCurrency=0xCD4557BF92B4D92be53561878E989A9C772f071A"
                                target="_blank"
                                rel="noreferrer"
                                // onClick={(e) => {
                                //     e.preventDefault();
                                // }}
                            >
                                Buy
                            </a>

                            {/* <a className="button btn-2" href="#">
                                <span>Connect</span>
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
