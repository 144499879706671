import { GetCryptoQuotesResponse, GetEDXContractInfoResponse } from './apiLibrary';

export type AppReducerState = {
    readonly tokenName: string;
    readonly tokenNamePlural: string;
    contractInfo: GetEDXContractInfoResponse | null | 'error';
    quoteData: GetCryptoQuotesResponse | null | 'error';
};

type UpdateContractInfoReducerAction = {
    type: 'updateContractInfo';
    payload: GetEDXContractInfoResponse | 'error';
};

type UpdateQuoteDataReducerAction = {
    type: 'updateQuoteData';
    payload: GetCryptoQuotesResponse | 'error';
};

export type AppReducerAction = UpdateContractInfoReducerAction | UpdateQuoteDataReducerAction;

export const appReducer = (state: AppReducerState, action: AppReducerAction): AppReducerState => {
    if (action.type === 'updateContractInfo') {
        return {
            ...state,
            contractInfo: action.payload,
        };
    } else if (action.type === 'updateQuoteData') {
        return {
            ...state,
            quoteData: action.payload,
        };
    }
    throw new Error(`Invalid action type`);
};
