import React from 'react';

import distributionChart from '../assets/allocation/distribution.svg';
import allocationChart from '../assets/allocation/allocation.svg';

type PropType = {};

const SectionAllocation = (props: PropType) => {
    return (
        <div id="allocation" className="funds-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Token Distribution and Budget Allocation</h2>
                            <p>
                                See how EduBits' total supply of 1,000 million tokens is distributed.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 mt_30">
                        <div className="chart-area">
                            <div className="chart-title text-center">Token Distribution</div>
                            <div className="img-area text-center">
                                <img
                                    className="img-fluid pie-chart"
                                    src={distributionChart}
                                    alt="Token Distribution"
                                />
                            </div>
                            <div className="parcent-area d-flex">
                                <ul className="area-1">
                                    <li className="p1">
                                        <span>50M</span>
                                        <p>EduBits Management</p>
                                    </li>
                                    <li className="p2 mt_30">
                                        <span>150M</span>
                                        <p>Scholarships Pool</p>
                                    </li>
                                    <li className="p3 mt_30">
                                        <span>500M</span>
                                        <p>Tokens Available to Public</p>
                                    </li>
                                </ul>
                                <ul className="area-2">
                                    <li className="p5">
                                        <span>50M</span>
                                        <p>Advisors/Partners</p>
                                    </li>
                                    <li className="p4 mt_30">
                                        <span>150M</span>
                                        <p>Team</p>
                                    </li>
                                    <li className="p6 mt_30">
                                        <span>100M</span>
                                        <p>Bounties</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt_30">
                        <div className="chart-area">
                            <div className="chart-title text-center">Budget Allocation</div>
                            <div className="img-area text-center">
                                <img
                                    className="img-fluid pie-chart"
                                    src={allocationChart}
                                    alt="Budget Allocation"
                                />
                            </div>
                            <div className="parcent-area d-flex">
                                <ul className="area-1">
                                    <li className="p7">
                                        <span>20%</span>
                                        <p>Staff</p>
                                    </li>
                                    <li className="p8 mt_30">
                                        <span>30%</span>
                                        <p>Course Development</p>
                                    </li>
                                    <li className="p9 mt_30">
                                        <span>20%</span>
                                        <p>Marketing and Business Development</p>
                                    </li>
                                </ul>
                                <ul className="area-2">
                                    <li className="p10">
                                        <span>10%</span>
                                        <p>Operational</p>
                                    </li>
                                    <li className="p11 mt_30">
                                        <span>10%</span>
                                        <p>Software Development</p>
                                    </li>
                                    <li className="p12 mt_30">
                                        <span>10%</span>
                                        <p>Reserve</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionAllocation;
