import React, { useContext } from 'react';

import { AppContext } from '../contexts/AppContext';

const useAppContext = () => {
    const value = useContext(AppContext);

    if (value === null) {
        throw new Error('Context not inited'); // cannot happen, but we need to shut up TypeScript (since we must init the reducer first)
    }

    return value;
};

export default useAppContext;
