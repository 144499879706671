import React, { useCallback, useMemo } from 'react';

import useAppContext from '../hooks/useAppContext';
import LoadingIndicator from './LoadingIndicator';

type PropType = {
    value: number;
    type: 'long' | 'short';
    sourceUnit: 'BNB' | 'EDX';
    className?: string;
    strikeThrough?: boolean;
};

const CryptoToUSD = (props: PropType) => {
    const { value, type, sourceUnit, className, strikeThrough } = props;

    const { getContractNumericValue, getBNBToUSDExchangeRate, formatValue } = useAppContext();

    const getClassName = useCallback(
        (localName) => {
            const names = [localName, className].filter(
                (x) => typeof x === 'string' && x.length > 0
            );

            return names.join(' ');
        },
        [className]
    );

    const spanStyle = useMemo(() => {
        return {
            textDecoration: strikeThrough ? 'line-through' : undefined,
        };
    }, [strikeThrough]);

    const displayUnit = 'USD';

    const _exchangeRate = getBNBToUSDExchangeRate();

    if (_exchangeRate === null) {
        return (
            <span className={getClassName('value-loading')} style={spanStyle}>
                <LoadingIndicator />
                &nbsp;{displayUnit}
            </span>
        );
    }

    if (_exchangeRate === 'error') {
        return (
            <span className={getClassName('value-error')} style={spanStyle}>
                &mdash;&mdash;&nbsp;{displayUnit}
            </span>
        );
    }

    let _value;
    if (sourceUnit === 'BNB') {
        _value = value * _exchangeRate;
    } else if (sourceUnit === 'EDX') {
        // make sure tokenRegularPriceEDX is valid
        const regularPriceEDX = getContractNumericValue('tokenRegularPriceEDX');
        if (regularPriceEDX === null) {
            return (
                <span className={getClassName('value-loading')} style={spanStyle}>
                    <LoadingIndicator />
                    &nbsp;{displayUnit}
                </span>
            );
        }
        if (regularPriceEDX === 'error') {
            return (
                <span className={getClassName('value-error')} style={spanStyle}>
                    &mdash;&mdash;&nbsp;{displayUnit}
                </span>
            );
        }
        _value = (value / regularPriceEDX) * _exchangeRate;
    } else {
        throw new Error('Unsupported unit');
    }

    return (
        <span className={getClassName('value-number')} style={spanStyle}>
            {formatValue(_value, sourceUnit, type, true)}
        </span>
    );
};

export default CryptoToUSD;
