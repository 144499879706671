import React, { useCallback, useState } from 'react';

import cx from 'classnames';

import useAppContext from '../hooks/useAppContext';

import NFTHeaderMenu from './NFTHeaderMenu';

import logo from '../assets/header/logo-white.svg';

type PropType = {
    activeSection: string | null;
};

const NFTHeader = (props: PropType) => {
    const { activeSection } = props;

    const { appState } = useAppContext();

    const [hasMobileMenu, setHasMobileMenu] = useState(false);

    const toggleMobileMenu = useCallback(() => {
        setHasMobileMenu((prevValue) => !prevValue);
    }, []);

    return (
        <header className="nft-header nav-active">
            <div className="container position-relative">
                <div className="row align-items-center form-row">
                    <div className="col-lg-2">
                        <div className="logo-box">
                            <a href="#nft-hero">
                                <img src={logo} alt="img" />
                                <span className="brand">{appState.tokenName}</span>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-10">
                        <NFTHeaderMenu className="menu text-center" activeSection={activeSection} />
                    </div>

                    <div
                        className={cx({
                            'hamburger-menu': true,
                            'active': hasMobileMenu,
                        })}
                        onClick={toggleMobileMenu}
                    >
                        <span className="line-top"></span>
                        <span className="line-center"></span>
                        <span className="line-bottom"></span>
                    </div>

                    <div
                        className={cx({
                            'mobile-menu-wrapper': true,
                            'show-menu': hasMobileMenu,
                        })}
                    >
                        <NFTHeaderMenu
                            className="mobile-menu"
                            activeSection={null}
                            onClick={toggleMobileMenu}
                        />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default NFTHeader;
