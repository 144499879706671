import React from 'react';

import check from '../assets/roadmap/check.svg';
import line from '../assets/roadmap/line.svg';

// decorative only
import shape1 from '../assets/roadmap/shape1.svg';
import shape2 from '../assets/roadmap/shape2.svg';
import shape9 from '../assets/roadmap/shape9.svg';
import shape12 from '../assets/roadmap/shape12.svg';
import shape13 from '../assets/roadmap/shape13.svg';

type PropType = {};

const SectionRoadmap = (props: PropType) => {
    return (
        <div id="roadmap" className="road-map-area">
            <div className="container position-relative">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Roadmap</h2>
                            {/* <p>Here are our goals for the next three years.</p> */}
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="line-area-wrapper">
                            <div className="row">
                                <div className="col-md-4 mt_30">
                                    <div className="map-box">
                                        <p className="date">2016-2019</p>
                                        <div className="radio-box active">
                                            <div className="unactive-radio">
                                                <span></span>
                                            </div>
                                            <div className="active-radio">
                                                <span>
                                                    <img src={check} alt="img" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="text-box">
                                            <h4>EduCode Academy</h4>
                                            <p>Creation of EduCode Academy and curriculum</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt_30">
                                    <div className="map-box">
                                        <p className="date">2022 Q1</p>
                                        <div className="radio-box active">
                                            <div className="unactive-radio">
                                                <span></span>
                                            </div>
                                            <div className="active-radio">
                                                <span>
                                                    <img src={check} alt="img" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="text-box">
                                            <h4>EduBits Token</h4>
                                            <p>Launch of EduBits token on the blockchain</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt_30">
                                    <div className="map-box">
                                        <p className="date">2022 Q2</p>
                                        <div className="radio-box ">
                                            <div className="unactive-radio">
                                                <span></span>
                                            </div>
                                            <div className="active-radio">
                                                <span>
                                                    <img src={check} alt="img" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="text-box">
                                            <h4>NFT Thoth Club</h4>
                                            <p>Launch of the NFT Thoth Club on OpenSea</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-top ">
                                <div className="col-md-4 mt_30">
                                    <div className="map-box">
                                        <p className="date">2022 Q2</p>
                                        <div className="radio-box">
                                            <div className="unactive-radio">
                                                <span></span>
                                            </div>
                                            <div className="active-radio">
                                                <span>
                                                    <img src={check} alt="img" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="text-box">
                                            <h4>EduBits App Prototype</h4>
                                            <p>
                                                Launch of the EduBits App prototype (early investors
                                                only)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt_30">
                                    <div className="map-box check-margin">
                                        <p className="date">2022 Q3-Q4</p>
                                        <div className="radio-box">
                                            <div className="unactive-radio">
                                                <span></span>
                                            </div>
                                            <div className="active-radio">
                                                <span>
                                                    <img src={check} alt="img" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="text-box">
                                            <h4>EduBits App</h4>
                                            <p>Launch of the EduBits App</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-top top-2">
                                <div className="col-md-4 mt_30">
                                    <div className="map-box">
                                        <p className="date">2023 Q1</p>
                                        <div className="radio-box">
                                            <div className="unactive-radio">
                                                <span></span>
                                            </div>
                                            <div className="active-radio">
                                                <span>
                                                    <img src={check} alt="img" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="text-box">
                                            <h4>Launch of EduBits Ecosystem</h4>
                                            <p>Public launch of EduBits transaction ecosystem</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt_30">
                                    <div className="map-box">
                                        <p className="date">2023 Q1</p>
                                        <div className="radio-box">
                                            <div className="unactive-radio">
                                                <span></span>
                                            </div>
                                            <div className="active-radio">
                                                <span>
                                                    <img src={check} alt="img" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="text-box">
                                            <h4>Virtual Course Development</h4>
                                            <p>Development of immersive courses</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt_30">
                                    <div className="map-box">
                                        <p className="date">2024 Q3-Q4</p>
                                        <div className="radio-box">
                                            <div className="unactive-radio">
                                                <span></span>
                                            </div>
                                            <div className="active-radio">
                                                <span>
                                                    <img src={check} alt="img" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="text-box">
                                            <h4>Virtual Courses</h4>
                                            <p>Launch of EduCode's virtual courses</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-shape-box">
                                <div className="shape shape1">
                                    <img src={shape1} alt="img" />
                                </div>
                                <div className="shape shape2">
                                    <img src={shape1} alt="img" />
                                </div>
                                <div className="shape shape3">
                                    <img src={shape2} alt="img" />
                                </div>
                                <div className="shape shape4">
                                    <img src={shape9} alt="img" />
                                </div>
                                <div className="shape shape5">
                                    <img src={shape9} alt="img" />
                                </div>
                                <div className="shape shape6">
                                    <img src={shape12} alt="img" />
                                </div>
                                <div className="shape shape7">
                                    <img src={shape13} alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="line-img-area">
                        <img className="img-fluid" src={line} alt="img" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionRoadmap;
