import React from 'react';

import TeamMember from './TeamMember';

type PropType = {};

const NFTSectionTeam = (props: PropType) => {
    return (
        <div id="nft-team" className="nft-section nft-team">
            <div className="container">
                <div className="subtitle centered">Meet our</div>
                <h2 className="title centered">Management Team</h2>
                <div className="row top-row">
                    <div className="col-lg-6 col-md-12">
                        <TeamMember memberKey="guyverson-vernous" />
                    </div>
                    {/* <div className="col-lg-6 col-md-12">
                        <TeamMember memberKey="myrtha-vernous-murat" />
                    </div> */}
                    <div className="col-lg-6 col-md-12">
                        <TeamMember memberKey="mignesh-parekh" />
                    </div>
                </div>
                <div className="row bottom-row">
                    <div className="col-lg-6 col-md-12">
                        <TeamMember memberKey="danyan-luo" />
                    </div>
                    {/* <div className="col-lg-6 col-md-12">
                        <TeamMember memberKey="eric-snow" />
                    </div> */}
                    <div className="col-lg-6 col-md-12">
                        <TeamMember memberKey="eric-hervet" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NFTSectionTeam;
