import React, { useCallback, useMemo } from 'react';

import { GetEDXContractInfoResponse } from '../lib/apiLibrary';

import useAppContext from '../hooks/useAppContext';
import LoadingIndicator from './LoadingIndicator';

const UNITS: Readonly<{
    [valueKey in keyof GetEDXContractInfoResponse]: 'BNB' | 'EDX' | '%' | '';
}> = {
    raisedBNB: 'BNB',
    raisedEDX: 'EDX',
    percent: '%',
    tokenRegularPriceEDX: 'EDX',
    tokenPresalePriceEDX: 'EDX',
    tokenPriceEDX: 'EDX',
    minInvestmentBNB: 'BNB',
    minInvestmentEDX: 'EDX',
    hardCapBNB: 'BNB',
    hardCapEDX: 'EDX',
    softCapBNB: 'BNB',
    softCapEDX: 'EDX',
    saleActive: '',
};

type PropType = {
    valueKey: keyof GetEDXContractInfoResponse;
    type: 'long' | 'short';
    className?: string;
    asUSD?: boolean;
    strikeThrough?: boolean;
};

const ContractValue = (props: PropType) => {
    const { valueKey, type, className, asUSD, strikeThrough } = props;

    const { getContractNumericValue, getBNBToUSDExchangeRate, formatValue } = useAppContext();

    const unit: 'BNB' | 'EDX' | '%' | '' = UNITS[valueKey];

    if (unit !== 'BNB' && unit !== 'EDX') throw new Error('Unsupported unit');

    const getClassName = useCallback(
        (localName) => {
            const names = [localName, className].filter(
                (x) => typeof x === 'string' && x.length > 0
            );

            return names.join(' ');
        },
        [className]
    );

    const spanStyle = useMemo(() => {
        return {
            textDecoration: strikeThrough ? 'line-through' : undefined,
        };
    }, [strikeThrough]);

    const displayUnit = useMemo(() => {
        return asUSD ? 'USD' : unit;
    }, [asUSD, unit]);

    let _value = getContractNumericValue(valueKey);

    if (typeof _value === 'boolean') {
        throw new Error('Boolean values not supported');
    }

    if (_value === null) {
        return (
            <span className={getClassName('value-loading')} style={spanStyle}>
                <LoadingIndicator />
                &nbsp;{displayUnit}
            </span>
        );
    }

    if (_value === 'error') {
        return (
            <span className={getClassName('value-error')} style={spanStyle}>
                &mdash;&mdash;&nbsp;{displayUnit}
            </span>
        );
    }

    // check if we want to convert to USD

    if (asUSD) {
        const _exchangeRate = getBNBToUSDExchangeRate();

        if (_exchangeRate === null) {
            return (
                <span className={getClassName('value-loading')} style={spanStyle}>
                    <LoadingIndicator />
                    &nbsp;{displayUnit}
                </span>
            );
        }

        if (_exchangeRate === 'error') {
            return (
                <span className={getClassName('value-error')} style={spanStyle}>
                    &mdash;&mdash;&nbsp;{displayUnit}
                </span>
            );
        }

        if (unit === 'BNB') {
            _value = _value * _exchangeRate;
        } else if (unit === 'EDX') {
            // make sure tokenRegularPriceEDX is valid
            const regularPriceEDX = getContractNumericValue('tokenRegularPriceEDX'); // should be valid if valueKey was valid
            if (typeof regularPriceEDX !== 'number') {
                throw new Error('Value should be valid');
            }
            _value = (_value / regularPriceEDX) * _exchangeRate;
        } else {
            throw new Error('Unsupported unit');
        }
    }

    return (
        <span className={getClassName('value-number')} style={spanStyle}>
            {formatValue(_value, unit, type, !!asUSD)}
        </span>
    );
};

export default ContractValue;
