import React, { useEffect, useState, useCallback } from 'react';

import throttle from 'lodash.throttle';

import NFTHeader from '../components/NFTHeader';
import NFTHero from '../components/NFTHero';

import NFTSectionAbout from '../components/NFTSectionAbout';
import NFTSectionRoadmap from '../components/NFTSectionRoadmap';
import NFTSectionSale from '../components/NFTSectionSale';
import NFTSectionCarousel from '../components/NFTSectionCarousel';
import NFTSectionEduBitsApp from '../components/NFTSectionEduBitsApp';
import NFTSectionEcosystem from '../components/NFTSectionEcosystem';
import NFTSectionTeam from '../components/NFTSectionTeam';
import NFTSectionFAQ from '../components/NFTSectionFAQ';
import NFTSectionSchools from '../components/NFTSectionSchools';

import NFTFooter from '../components/NFTFooter';

import '../styles/FranchiseNFTs.scss';

type PropType = {};

const FranchiseNFTsView = (props: PropType) => {
    useEffect(() => {
        document.querySelector('body')?.classList.add('nft');

        return () => {
            document.querySelector('body')?.classList.remove('nft');
        };
    });

    useEffect(() => {
        document.title = 'Thoth: The Memecoin for Education';
    });

    const [activeSection, setActiveSection] = useState<string | null>(null);

    const spyActiveSection = useCallback(() => {
        let activeSection = null;
        const scrollY = window.scrollY + window.outerHeight / 2;
        for (const section of Array.from(document.querySelectorAll('.nft-section'))) {
            const offsetTop = (section as any).offsetTop;
            const clientHeight = (section as any).clientHeight;
            if (scrollY > offsetTop && scrollY < offsetTop + clientHeight) {
                activeSection = section.id.replace('#', '');
                break;
            }
        }
        if (activeSection === 'nft-ecosystem') {
            activeSection = 'nft-edubits-app';
        }
        setActiveSection(activeSection);
        // console.log('ACTIVE SECTION: ', activeSection);
    }, [setActiveSection]);

    const throttleScroll = throttle(spyActiveSection, 100);

    useEffect(() => {
        window.addEventListener('scroll', throttleScroll);

        return () => {
            window.removeEventListener('scroll', throttleScroll);
        };
    }, [throttleScroll]);

    // if page is reloaded from cache, make sure this is in sync
    useEffect(() => {
        spyActiveSection();
    }, []);

    return (
        <div className="FranchiseNFTsView">
            <NFTHeader activeSection={activeSection} />

            <NFTHero />

            <NFTSectionAbout />

            <NFTSectionRoadmap />

            <NFTSectionSale />

            <NFTSectionEduBitsApp />

            <NFTSectionEcosystem />

            {/* <NFTSectionCarousel /> */}

            <NFTSectionTeam />

            <NFTSectionFAQ />

            <NFTSectionSchools />

            <NFTFooter />
        </div>
    );
};

export default FranchiseNFTsView;
