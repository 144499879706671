import axios from 'axios';

const DEBUG = false;

const instance = axios.create({
    baseURL: DEBUG ? 'http://localhost:3000/edx/' : 'https://api.educode.org/edx/',
    timeout: 30 * 1000,
});

instance.interceptors.request.use(
    function (config) {
        config.params = { ...config.params, __t: Date.now() };
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export type SendEmailRequest = Readonly<{
    fullName: string;
    emailAddress: string;
    message: string;
    token: string; // the captcha token, for backend validation
}>;

export type RegisterMailingListRequest = Readonly<{
    listKey: string;
    emailAddress: string;
    firstName?: string;
    lastName?: string;
}>;

export type GetEDXContractInfoRequest = void;

export type GetEDXContractInfoResponse = Readonly<{
    raisedBNB: number; // amount raised, in BNB
    raisedEDX: number; // amount raised, in EDX
    percent: number; // percentage of hard cap reached (as [0, 100] value)
    tokenRegularPriceEDX: number; // tokenPriceEDX goes up to 20,000 during pre-sale, but this one stays the same
    tokenPresalePriceEDX: number; // tokenPriceEDX goes up to 20,000 during pre-sale, but this one stays the same
    tokenPriceEDX: number; // how many EDX tokens does 1 BNB buy (so, NOT the price) -- the CURRENT price (regular/pre-sale)
    minInvestmentBNB: number; // minimal investment amount, in BNB
    minInvestmentEDX: number; // minimal investment amount, in EDX
    hardCapBNB: number; // maximal total investment amount, in BNB
    hardCapEDX: number; // maximal total investment amount, in EDX
    softCapBNB: number; // minimal total investment amount, in BNB
    softCapEDX: number; // minimal total investment amount, in EDX
    saleActive: boolean; // true if the sale is currently active
}>;

export type GetCryptoQuotesRequest = void;

export type GetCryptoQuotesResponse = {
    [symbol: string]: Readonly<{
        symbol: string; // e.g. ETH
        slug: string; // e.g. ethereum
        name: string; // e.g. Ethereum
        quoteUSD: Readonly<{
            price: number; // in USD
            lastUpdated: Date;
        }>;
    }>;
};

export async function sendEmail(data: SendEmailRequest): Promise<void> {
    const res = await instance.post('/send-email', data);
    const errorMsg = res.data.data || 'unknownError';
    if (!res.data.successful) throw new Error('Request failed: ' + errorMsg);
}

export async function registerMailingList(data: RegisterMailingListRequest): Promise<void> {
    const res = await instance.post('/register-mailing-list', data);
    const errorMsg = res.data.data || 'unknownError';
    if (!res.data.successful) throw new Error('Request failed: ' + errorMsg); // never fails
}

export async function getEDXContractInfo(
    data: GetEDXContractInfoRequest
): Promise<GetEDXContractInfoResponse> {
    const res = await instance.get('/contract-info');
    const errorMsg = res.data.data || 'unknownError';
    if (!res.data.successful) throw new Error('Request failed: ' + errorMsg);
    const result = res.data.data;
    if (!result || typeof result !== 'object') throw new Error('Invalid result');
    console.warn('CONTRACT INFO: ', result);
    const valueKeys: ReadonlyArray<keyof GetEDXContractInfoResponse> = [
        // excluding the boolean values
        'raisedBNB',
        'raisedEDX',
        'percent',
        'tokenRegularPriceEDX',
        'tokenPresalePriceEDX',
        'tokenPriceEDX',
        'minInvestmentBNB',
        'minInvestmentEDX',
        'hardCapBNB',
        'hardCapEDX',
        'softCapBNB',
        'softCapEDX',
    ];
    for (const valueKey of valueKeys) {
        if (typeof result[valueKey] !== 'number') {
            throw new Error(`Missing property ${valueKey} in result`);
        }
    }
    if (typeof result.saleActive !== 'boolean') {
        throw new Error('Missing property saleActive in result');
    }
    return result;
}

export async function getCryptoQuotes(
    data: GetCryptoQuotesRequest
): Promise<Readonly<GetCryptoQuotesResponse>> {
    const res = await instance.get('/crypto-quotes');
    const errorMsg = res.data.data || 'unknownError';
    if (!res.data.successful) throw new Error('Request failed: ' + errorMsg);
    const quoteData = res.data.data;
    if (!quoteData || typeof quoteData !== 'object') throw new Error('Invalid quoteData');
    // console.warn('RAW CRYPTO QUOTES: ', quoteData);
    // format the result
    const result: GetCryptoQuotesResponse = {};
    for (const id in quoteData) {
        const item = quoteData[id];
        if (!item || typeof item !== 'object') throw new Error('Invalid item');
        const { symbol, slug, name, quote } = item;
        if (typeof symbol !== 'string' || !symbol.length) throw new Error('Invalid symbol');
        if (typeof slug !== 'string' || !slug.length) throw new Error('Invalid slug');
        if (typeof name !== 'string' || !name.length) throw new Error('Invalid name');
        if (!quote || typeof quote !== 'object') throw new Error('Invalid quote');
        const usdQuote = quote['USD'];
        if (!usdQuote || typeof usdQuote !== 'object') throw new Error('Invalid usdQuote');
        const { price, last_updated } = usdQuote;
        if (typeof price !== 'number') throw new Error('Invalid quote price');
        if (typeof last_updated !== 'string' || !last_updated.length)
            throw new Error('Invalid quote last updated');
        result[symbol] = {
            symbol: symbol,
            slug: slug,
            name: name,
            quoteUSD: {
                price: price,
                lastUpdated: new Date(last_updated),
            },
        };
    }
    console.warn('FORMATTED CRYPTO QUOTES: ', result);
    return result;
}
