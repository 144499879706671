import React from 'react';

import TeamMember from './TeamMember';

type PropType = {};

const SectionTeam = (props: PropType) => {
    return (
        <div id="team" className="team-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Management Team</h2>
                            {/* <p>
                                TODO: find something to write here
                            </p> */}
                        </div>
                    </div>
                    {/* <div className="col-12">
                        <div className="section-title investor-board">
                            <h2>Investor Board</h2>
                        </div>
                    </div> */}

                    <div className="col-xl-6 col-lg-6 col-sm-6 mt_30">
                        <TeamMember memberKey="guyverson-vernous" />
                    </div>
                    {/* <div className="col-xl-6 col-lg-6 col-sm-6 mt_30">
                        <TeamMember memberKey="myrtha-vernous-murat" />
                    </div> */}
                    <div className="col-xl-6 col-lg-6 col-sm-6 mt_30">
                        <TeamMember memberKey="mignesh-parekh" />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-6 mt_30">
                        <TeamMember memberKey="danyan-luo" />
                    </div>
                    {/* <div className="col-xl-6 col-lg-6 col-sm-6 mt_30">
                        <TeamMember memberKey="eric-snow" />
                    </div> */}
                    <div className="col-xl-6 col-lg-6 col-sm-6 mt_30">
                        <TeamMember memberKey="eric-hervet" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionTeam;
