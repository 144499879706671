import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AppContextProvider } from './contexts/AppContext';

import Home from './views/HomeView';

import FranchiseNFTs from './views/FranchiseNFTsView';

import './styles/App.scss';

function App() {
    return (
        <AppContextProvider>
            <BrowserRouter>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<FranchiseNFTs />} />
                        <Route path="/edubits" element={<Home />} />
                    </Routes>
                </div>
            </BrowserRouter>
        </AppContextProvider>
    );
}

export default App;
