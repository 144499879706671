import React from 'react';

import useAppContext from '../hooks/useAppContext';

import handHolding from '../assets/top-features/hand-holding-usd-solid.svg';
import convertCard from '../assets/top-features/convert-card.svg';
import globe from '../assets/top-features/globe-solid.svg';
import logoWhite from '../assets/top-features/logo-white.svg';
import wallet from '../assets/top-features/wallet-solid.svg';
import tabletCoins from '../assets/top-features/tablet-alt-solid-with-coins-solid.svg';

type PropType = {};

const SectionTopFeatures = (props: PropType) => {
    const { appState } = useAppContext();

    return (
        <div id="features" className="edtoken-area">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>About</h2>
                            <p>
                                EduBits is the first Learn-to-Earn app in the web3 era that lets you
                                earn money while browsing the Web. EduBits builds on top of our
                                gamified online learning platform, EduCode Academy, which has been
                                used by over 650,000 learners around the world since 2016.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mt_30">
                        <div className="edtoken-text-box box-1">
                            <div className="item">
                                <div className="item-icon item1">
                                    <img src={logoWhite} alt="img" />
                                </div>
                                <h3>The New Knowledge Economy</h3>
                                <p>
                                    EduBits will reward content creators for publishing educational
                                    materials, which can then be purchased with EduBits.
                                </p>
                            </div>
                            <div className="item mt_70 item-mar">
                                <div className="item-icon item2">
                                    <img src={globe} alt="img" />
                                </div>
                                <h3>EduCode Academy</h3>
                                <p>
                                    We aim to expand the curriculum to include other school
                                    subjects, ike mathematics, biology, science, and more.
                                </p>
                            </div>
                            <div className="item mt_70">
                                <div className="item-icon item3">
                                    <img src={convertCard} alt="img" />
                                </div>
                                <h3>A Knowledge Ecosystem</h3>
                                <p>
                                    We will create interoperable software libraries that will
                                    establish EduBits as the new currency for education and
                                    facilitate transactions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 circle-area">
                        <div className="img-area" />
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mt_30">
                        <div className="edtoken-text-box box-2">
                            <div className="item left-align">
                                <div className="item-icon item4">
                                    <img src={tabletCoins} alt="img" />
                                </div>
                                <h3>Learn-to-Earn</h3>
                                <p>
                                    EduBits give you the incentive to learn everything you ever
                                    wanted to learn, inside or outside a classroom.
                                </p>
                            </div>
                            <div className="item item2 left-align mt_70">
                                <div className="item-icon item5">
                                    <img src={handHolding} alt="img" />
                                </div>
                                <h3>Scholarships Pool</h3>
                                <p>
                                    EduCode's scholarships pool will be used to fund access to
                                    EduCode Academy and to our partner offerings.
                                </p>
                            </div>
                            <div className="item left-align mt_70">
                                <div className="item-icon item6">
                                    <img src={wallet} alt="img" />
                                </div>
                                <h3>EduBits App</h3>
                                <p>
                                    Earn money while browsing the Web, watching videos and reading
                                    e-books or articles. Make your time count!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionTopFeatures;
