import React from 'react';

import Slider from 'react-slick';

import useAppContext from '../hooks/useAppContext';

import statusUp from '../assets/nft/app-icon1.svg';
import handsHelping from '../assets/nft/app-icon2.svg';
import shield from '../assets/nft/app-icon3.svg';
import wallet from '../assets/nft/app-icon4.svg';

import carousel1 from '../assets/advantages/carousel/image-1.png';
import carousel2 from '../assets/advantages/carousel/image-2.png';
import carousel3 from '../assets/advantages/carousel/image-3.png';
import carousel4 from '../assets/advantages/carousel/image-4.png';

type PropType = {};

const NFTSectionEduBitsApp = (props: PropType) => {
    const { appState } = useAppContext();

    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 575.98,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div id="nft-edubits-app" className="nft-section nft-edubits-app">
            <div className="container top">
                <div className="row">
                    <div className="col-12">
                        <div className="subtitle centered">Coming summer 2023</div>
                        <h2 className="title centered">EduBits App</h2>
                        <p className="description centered padded">
                            How many hours of video have you watched online, while marketers and big
                            corporations raked in the profits? As they say, time is money. Time to
                            make it count with the EduBits app, the first Learn-to-Earn app in the
                            web3 era that lets you earn money while browsing the Web.
                        </p>
                    </div>
                </div>

                <div className="row about-blurbs">
                    <div className="col-xl-3 col-sm-6 col-xs-12 about-blurb">
                        <div className="about-icon">
                            <img src={statusUp} alt="img" />
                        </div>
                        <h3 className="about-title">Learn-to-Earn</h3>
                        <p className="about-description">
                            Get the incentive to learn everything you ever wanted to learn, inside
                            or outside a classroom.
                        </p>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-xs-12 about-blurb">
                        <div className="about-icon">
                            <img src={handsHelping} alt="img" />
                        </div>
                        <h3 className="about-title">Read and Earn</h3>
                        <p className="about-description">
                            Read a book, a magazine or the news on the Web and be rewarded in cash
                            for your time spent.
                        </p>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-xs-12 about-blurb">
                        <div className="about-icon">
                            <img src={shield} alt="img" />
                        </div>
                        <h3 className="about-title">Watch and Earn</h3>
                        <p className="about-description">
                            Get cash rewards for watching educational videos from various video
                            providers on the Web.
                        </p>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-xs-12 about-blurb">
                        <div className="about-icon">
                            <img src={wallet} alt="img" />
                        </div>
                        <h3 className="about-title">Exchangeable</h3>
                        <p className="about-description">
                            The tokens earned can be easily exchanged for other cryptocurrencies or
                            fiat currencies.
                        </p>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12 app-carousel">
                            <Slider {...carouselSettings}>
                                <div className="carousel-image-wrapper">
                                    <img className="carousel-image" src={carousel1} alt="" />
                                </div>
                                <div className="carousel-image-wrapper">
                                    <img className="carousel-image" src={carousel2} alt="" />
                                </div>
                                <div className="carousel-image-wrapper">
                                    <img className="carousel-image" src={carousel3} alt="" />
                                </div>
                                <div className="carousel-image-wrapper">
                                    <img className="carousel-image" src={carousel4} alt="" />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NFTSectionEduBitsApp;
