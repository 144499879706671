import React from 'react';

import hero1 from '../assets/nft/hero1.jpg';
import hero2 from '../assets/nft/hero2.jpg';
import hero3 from '../assets/nft/hero3.jpg';
import hero4 from '../assets/nft/hero4.jpg';
import hero5 from '../assets/nft/hero5.jpg';

import heroIcon1 from '../assets/nft/hero-icon1.svg';
import heroIcon2 from '../assets/nft/hero-icon2.svg';
import heroIcon3 from '../assets/nft/hero-icon3.svg';
import heroIcon4 from '../assets/nft/hero-icon4.svg';

type PropType = {};

const BASE_URL = 'https://api.educode.org/edx/';

const NFTHero = (props: PropType) => {
    return (
        <div id="nft-hero" className="nft-hero d-flex align-items-center">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-6 mt_30">
                        <div className="hero-text">
                            <h1>
                                Thoth: The Memecoin for
                                <span className="colored">
                                    <span>Education</span>
                                </span>
                            </h1>
                            <p className="description white spaced-out">
                                Thoth, inspired by the ancient Egyptian god of knowledge and
                                science, has emerged as a catalyst for change in the field of
                                education. By leveraging the power of blockchain technology, Thoth
                                aims to revolutionize the way we approach education and incentivize
                                learning.
                            </p>
                            {/* <a
                                className="btn btn-primary btn-lg"
                                href="https://www.pinksale.finance/launchpad/0x19175460BaDE6A4b72dC546BE15A786Ab3B1ecd7?chain=ETH"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Buy
                            </a> */}
                            <a
                                className="btn btn-primary btn-lg"
                                href={`${BASE_URL}documents/whitepaper?__t=${Date.now()}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                White Paper
                            </a>
                            <div className="checks">
                                {/* <p className="check">
                                    ✅<span className="spacer"></span>Launched by a
                                    legally-registered company
                                    <br className="line-break" /> ✅<span className="spacer"></span>
                                    No team tokens
                                </p> */}
                                <p className="check">
                                    🟢<span className="spacer"></span>KYC 🔵
                                    <span className="spacer"></span>AUDIT 🟣
                                    <span className="spacer"></span>SAFU Code
                                    <br className="line-break" /> 🚀
                                    <span className="spacer"></span>CMC & CG fast track
                                    <br />
                                    Token Contract:{' '}
                                    <a
                                        className="bc-address"
                                        href="https://etherscan.io/token/0xa232c1125602D44e2d51D6352b9a6B5e010f52c2"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        0xa232c1125602D44e2d51D6352b9a6B5e010f52c2
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt_30">
                        <div className="decoration">
                            <img className="nft-image hero1" src={hero1} alt="" />
                            <img className="nft-image hero2" src={hero2} alt="" />
                            <img className="nft-image hero3" src={hero3} alt="" />
                            <img className="nft-image hero4" src={hero4} alt="" />
                            <img className="nft-image hero5" src={hero5} alt="" />

                            <div className="nft-icon hero1">
                                <img src={heroIcon1} alt="" />
                            </div>
                            <div className="nft-icon hero2">
                                <img src={heroIcon2} alt="" />
                            </div>
                            <div className="nft-icon hero3">
                                <img src={heroIcon3} alt="" />
                            </div>
                            <div className="nft-icon hero4">
                                <img src={heroIcon4} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NFTHero;
