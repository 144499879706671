import React, { Dispatch, useCallback, useEffect, useMemo, useReducer } from 'react';

import { getCryptoQuotes, getEDXContractInfo, GetEDXContractInfoResponse } from '../lib/apiLibrary';

import { appReducer, AppReducerAction, AppReducerState } from '../lib/appReducer';

type ContextValue = Readonly<{
    appState: AppReducerState;
    appDispatch: Dispatch<AppReducerAction>;
    getContractNumericValue: (
        valueKey: keyof GetEDXContractInfoResponse
    ) => number | null | 'error';
    getContractNumericValueWithDefault: (
        valueKey: keyof GetEDXContractInfoResponse,
        defaultValue: number
    ) => number;
    refreshContractInfo: () => Promise<void>;
    getBNBToUSDExchangeRate: () => number | null | 'error';
    refreshQuoteData: () => Promise<void>;
    formatValue: (
        value: number,
        unit: 'BNB' | 'EDX',
        type: 'long' | 'short',
        asUSD: boolean
    ) => React.ReactFragment;
}>;

export const AppContext = React.createContext<ContextValue | null>(null);

type PropType = {
    children: React.ReactChild;
};

export const AppContextProvider = (props: PropType) => {
    const { children } = props;

    const [appState, appDispatch] = useReducer(appReducer, null, (arg: null) => {
        return {
            tokenName: 'EduBits',
            tokenNamePlural: 'EduBits',
            contractInfo: null, // fetched async
            quoteData: null, // fetched async
        };
    });

    const { contractInfo, quoteData } = appState;

    const getContractNumericValue = useCallback(
        (valueKey: keyof GetEDXContractInfoResponse): number | null | 'error' => {
            // does not support boolean values, only numbers
            if (!contractInfo) {
                return null;
            }
            if (contractInfo === 'error') {
                return 'error';
            }
            const _value = contractInfo[valueKey];
            if (typeof _value === 'boolean') {
                throw new Error('Boolean values not supported');
            } else if (typeof _value === 'number') {
                return _value;
            } else {
                throw new Error('Invalid value type');
            }
        },
        [contractInfo]
    );

    const getContractNumericValueWithDefault = useCallback(
        (valueKey: keyof GetEDXContractInfoResponse, defaultValue: number): number => {
            // defaultValue is used if value is still loading or errored out
            // does not work with boolean values
            const _value = getContractNumericValue(valueKey);
            return _value === null || _value === 'error' ? defaultValue : _value;
        },
        [getContractNumericValue]
    );

    const refreshContractInfo = useCallback(async () => {
        try {
            const newContractInfo = await getEDXContractInfo();
            appDispatch({ type: 'updateContractInfo', payload: newContractInfo });
        } catch (error) {
            console.error('FAILED TO REFRESH CONTRACT INFO: ', error);
            appDispatch({ type: 'updateContractInfo', payload: 'error' });
        }
    }, []);

    // update the contract info on mount

    useEffect(() => {
        refreshContractInfo(); // not awaited
    }, [refreshContractInfo]);

    const getBNBToUSDExchangeRate = useCallback((): number | null | 'error' => {
        // returns the value, in USD, of 1 BNB
        if (!quoteData) {
            return null;
        }
        if (quoteData === 'error') {
            return 'error';
        }
        const bnbData = quoteData['BNB'];
        if (!bnbData) {
            return 'error';
        }
        return bnbData.quoteUSD.price;
    }, [quoteData]);

    const refreshQuoteData = useCallback(async () => {
        try {
            const newQuoteData = await getCryptoQuotes();
            appDispatch({ type: 'updateQuoteData', payload: newQuoteData });
        } catch (error) {
            console.error('FAILED TO REFRESH QUOTE DATA: ', error);
            appDispatch({ type: 'updateQuoteData', payload: 'error' });
        }
    }, []);

    // update the quote data on mount

    useEffect(() => {
        refreshQuoteData(); // not awaited
    }, [refreshQuoteData]);

    const formatValue = useCallback(
        (
            _value: number,
            unit: 'BNB' | 'EDX',
            type: 'long' | 'short',
            asUSD: boolean
        ): React.ReactFragment => {
            let dspValue;
            let prefix;
            if (_value > 1e6) {
                dspValue = (_value / 1e6).toFixed(asUSD ? 2 : 1);
                prefix = type === 'long' ? 'million' : 'M';
            } else if (_value > 1e3) {
                if (type === 'long') {
                    // format as XX,XXX.X + unit
                    const rounded = Number(_value.toFixed(asUSD ? 2 : 1));
                    const thousands = Math.floor(rounded / 1e3);
                    let remaining = (rounded % 1e3).toFixed(asUSD ? 2 : 1); // % keeps decimals
                    if (remaining.indexOf('.') < 0) {
                        remaining += '.0'; // should not happen, but just in case
                    }
                    const [whole, decimals] = remaining.split('.');
                    dspValue = thousands + ',' + whole.padStart(3, '0') + '.' + decimals;
                    prefix = '';
                } else {
                    // format as XX.X K + unit
                    dspValue = (_value / 1e3).toFixed(asUSD ? 2 : 1);
                    prefix = 'K';
                }
            } else {
                dspValue = _value.toFixed(asUSD ? 2 : 1);
            }
            if (dspValue.substring(dspValue.length - 2, dspValue.length) === '.0') {
                dspValue = dspValue.substring(0, dspValue.length - 2);
            }
            const displayUnit = asUSD ? 'USD' : unit;
            return type === 'long' ? (
                <>
                    {dspValue}&nbsp;{prefix}&nbsp;{displayUnit}
                </>
            ) : (
                <>
                    {dspValue}
                    {prefix}&nbsp;{displayUnit}
                </>
            );
        },
        []
    );

    const value: ContextValue = useMemo(() => {
        return {
            appState: appState,
            appDispatch: appDispatch, // stable
            getContractNumericValue: getContractNumericValue,
            getContractNumericValueWithDefault: getContractNumericValueWithDefault,
            refreshContractInfo: refreshContractInfo,
            getBNBToUSDExchangeRate: getBNBToUSDExchangeRate,
            refreshQuoteData: refreshQuoteData,
            formatValue: formatValue,
        };
    }, [
        appState,
        getContractNumericValue,
        getContractNumericValueWithDefault,
        refreshContractInfo,
        getBNBToUSDExchangeRate,
        refreshQuoteData,
        formatValue,
    ]);

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
