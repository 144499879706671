import React, { useEffect } from 'react';

import Header from '../components/Header';
import Hero from '../components/Hero';

import SectionTopFeatures from '../components/SectionTopFeatures';
import SectionAdvantages from '../components/SectionAdvantages';
import SectionAllocation from '../components/SectionAllocation';
import SectionTokenSale from '../components/SectionTokenSale';
import SectionRoadmap from '../components/SectionRoadmap';
import SectionDocuments from '../components/SectionDocuments';
import SectionTeam from '../components/SectionTeam';
import SectionSchools from '../components/SectionSchools';
import SectionPartners from '../components/SectionPartners';
import SectionFAQ from '../components/SectionFAQ';
import SectionContact from '../components/SectionContact';

import Footer from '../components/Footer';

import '../styles/Home.scss';

type PropType = {};

const HomeView = (props: PropType) => {

    useEffect(() => {

        document.querySelector('body')?.classList.add('ido');

        return () => {

            document.querySelector('body')?.classList.remove('ido');
        };
    });

    return (
        <div className="HomeView">
            {/* the Header contains AnchorLinks to the Sections below (by DOM id) */}
            <Header />

            <Hero />

            {/* #features */}
            <SectionTopFeatures />

            {/* #advantages */}
            <SectionAdvantages />

            {/* #allocation */}
            <SectionAllocation />

            {/* #sale */}
            <SectionTokenSale />

            {/* #roadmap */}
            <SectionRoadmap />

            {/* #documents */}
            <SectionDocuments />

            {/* #team */}
            <SectionTeam />

            {/* #schools */}
            <SectionSchools />

            {/* #partners */}
            <SectionPartners />

            {/* #faq */}
            <SectionFAQ />

            {/* #contact */}
            <SectionContact />

            <Footer />
        </div>
    );
};

export default HomeView;
