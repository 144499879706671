import React from 'react';

import useAppContext from '../hooks/useAppContext';

import ContractValue from './ContractValue';

type PropType = {
    variant?: 'small' | 'normal'; // default=normal
};

const SaleProgress = (props: PropType) => {
    const { variant } = props;

    const { getContractNumericValueWithDefault } = useAppContext();

    // we use the markup and style of ion.rangeSlider (jQuery plugin),
    // inline styles and all, but not the code, because, well we're in 2022

    const percent = getContractNumericValueWithDefault('percent', 0);

    const dspPercent = percent * 0.85; // rescale to 85%

    return (
        <div className="ion-range">
            {variant !== 'small' && (
                <div className="label-box d-flex justify-content-between">
                    <span className="label">
                        Raised -{' '}
                        <strong>
                            {' '}
                            <ContractValue valueKey="raisedEDX" type="short" />
                        </strong>
                    </span>
                    <span className="label">
                        Target -{' '}
                        <strong>
                            200M EDX
                            {/* <ContractValue valueKey="hardCapEDX" type="short" /> */}
                        </strong>
                    </span>
                </div>
            )}
            <span className="irs irs--round js-irs-0">
                <span className="irs">
                    <span className="irs-line"></span>
                </span>
                <span className="irs-grid"></span>
                <span
                    className="irs-bar irs-bar--single"
                    style={{ left: '0', width: dspPercent + (variant === 'small' ? 0 : 1.4) + '%' }}
                ></span>
                <span
                    className="irs-handle single"
                    style={{ left: dspPercent + '%', marginTop: '1px' }}
                >
                    <i></i>
                    <i></i>
                    <i></i>
                </span>
            </span>
            <div className="position-box box-1" style={{ left: '10.625%' }}>
                Soft cap
                <br />
                20M EDX
                {/* <ContractValue valueKey="softCapEDX" type="short" /> */}
            </div>
            <div className="position-box box-2" style={{ left: '85%' }}>
                Hard cap
                <br />
                200M EDX
                {/* <ContractValue valueKey="hardCapEDX" type="short" /> */}
            </div>
        </div>
    );
};

export default SaleProgress;
