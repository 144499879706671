import React from 'react';

import useAppContext from '../hooks/useAppContext';

import SaleTimer from './SaleTimer';
import SaleProgress from './SaleProgress';
import ContractValue from './ContractValue';
import CryptoToUSD from './CryptoToUSD';

type PropType = {};

const SectionTokenSale = (props: PropType) => {
    const { appState, getContractNumericValue } = useAppContext();

    const isPreSale = false; //getContractNumericValue('tokenPriceEDX') === 20000;

    return (
        <div id="sale" className="sale-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Token Sale</h2>
                            {/* <p>Join our IDO today and help us make the world a smarter place.</p> */}
                        </div>
                    </div>
                    <div className="col-md-11">
                        <div className="sale-box-wrapper">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="token-box-wrapper">
                                                <div className="item">
                                                    <span>Start</span>
                                                    <p>June 6, 2023</p>
                                                </div>
                                                <div className="item">
                                                    <span>Accepted currencies</span>
                                                    <p>Binance Coin (BNB) only</p>
                                                </div>
                                                <div className="item">
                                                    <span>Token exchange rate</span>
                                                    {!!isPreSale && (
                                                        <>
                                                            <div className="presale-info">
                                                                50% off during pre-sale
                                                            </div>
                                                            <p>
                                                                1 BNB ={' '}
                                                                <ContractValue
                                                                    valueKey="tokenRegularPriceEDX"
                                                                    type="long"
                                                                    strikeThrough
                                                                />
                                                                <br />1 BNB ={' '}
                                                                <ContractValue
                                                                    valueKey="tokenPresalePriceEDX"
                                                                    type="long"
                                                                    className="updated-price"
                                                                />
                                                                <span className="presale-price">
                                                                    50% Discount
                                                                </span>
                                                                <br /> (1 BNB ≈{' '}
                                                                <CryptoToUSD
                                                                    value={1}
                                                                    sourceUnit="BNB"
                                                                    type="short"
                                                                />
                                                                )
                                                            </p>
                                                        </>
                                                    )}
                                                    {!isPreSale && (
                                                        <p>
                                                            1 BNB ={' '}
                                                            <ContractValue
                                                                valueKey="tokenRegularPriceEDX"
                                                                type="long"
                                                            />
                                                            <br /> (1 BNB ≈{' '}
                                                            <CryptoToUSD
                                                                value={1}
                                                                sourceUnit="BNB"
                                                                type="short"
                                                            />
                                                            )
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="token-box-wrapper">
                                                <div className="item">
                                                    <span>End</span>
                                                    <p>June 30, 2023</p>
                                                </div>
                                                <div className="item">
                                                    <span>Number of tokens for sale</span>
                                                    <p>
                                                        500 million EDX
                                                        {/* <ContractValue
                                                            valueKey="hardCapEDX"
                                                            type="long"
                                                        /> */}
                                                    </p>
                                                </div>
                                                <div className="item">
                                                    <span>Minimal transaction</span>
                                                    <p>
                                                        <ContractValue
                                                            valueKey="minInvestmentBNB"
                                                            type="short"
                                                        />{' '}
                                                        ={' '}
                                                        <ContractValue
                                                            valueKey="minInvestmentEDX"
                                                            type="long"
                                                        />{' '}
                                                        <br />(
                                                        <ContractValue
                                                            valueKey="minInvestmentBNB"
                                                            type="short"
                                                        />{' '}
                                                        ≈{' '}
                                                        <ContractValue
                                                            valueKey="minInvestmentBNB"
                                                            type="short"
                                                            asUSD
                                                        />
                                                        )
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12" style={{ display: 'block' }}>
                                    <div className="token-counter-box box">
                                        <div className="token-counter" style={{ display: 'block' }}>
                                            <SaleTimer
                                                header="Token presale ends in:"
                                                variant="edubits"
                                                until={new Date('2023-06-08T08:06:00')}
                                            />
                                            <div
                                                className="home-range-area token-range"
                                                style={{ display: 'none' }}
                                            >
                                                <SaleProgress variant="small" />
                                            </div>
                                            <a
                                                href="#"
                                                className="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                                style={{ display: 'none' }}
                                            >
                                                Get EduBits
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clender-wrapper" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionTokenSale;
