import React, { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';

type PropType = {
    show: boolean;
    title: React.ReactChild;
    className?: string;
    handleClose: () => void;
    children: React.ReactChild;
};

const AlertModal = (props: PropType) => {
    const { show, title, className, handleClose, children } = props;

    return (
        <Modal show={show} className={className} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button className="button" variant="primary" onClick={handleClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AlertModal;
