import React from 'react';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import SectionFAQGeneral from './SectionFAQ-general';
import SectionFAQIDO from './SectionFAQ-ido';
import SectionFAQScholarships from './SectionFAQ-scholarships';
import SectionFAQEcosystem from './SectionFAQ-ecosystem';

type PropType = {};

const SectionFAQ = (props: PropType) => {
    return (
        <div id="faq" className="faq-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Have Any Questions?</h2>
                            <p>
                                Here are the answers to some frequently asked questions.
                            </p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="tab-area mt_30">
                            <Tabs className="mb-3" defaultActiveKey="general">
                                <Tab className="mt_30" eventKey="general" title="General">
                                    <SectionFAQGeneral />
                                </Tab>
                                <Tab className="mt_30" eventKey="ido" title="IDO">
                                    <SectionFAQIDO />
                                </Tab>
                                <Tab className="mt_30" eventKey="scholarships" title="Scholarships">
                                    <SectionFAQScholarships />
                                </Tab>
                                <Tab className="mt_30" eventKey="ecosystem" title="Ecosystem">
                                    <SectionFAQEcosystem />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionFAQ;
