import React from 'react';

type PropType = {};

const LoadingIndicator = (props: PropType) => {
    return (
        <span className="lds-ellipsis">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </span>
    );
};

export default LoadingIndicator;
