import React from 'react';

import useAppContext from '../hooks/useAppContext';

import handHolding from '../assets/top-features/hand-holding-usd-solid.svg';
import convertCard from '../assets/top-features/convert-card.svg';
import globe from '../assets/top-features/globe-solid.svg';
import logoWhite from '../assets/top-features/logo-white.svg';
import wallet from '../assets/top-features/wallet-solid.svg';
import tabletCoins from '../assets/top-features/tablet-alt-solid-with-coins-solid.svg';

type PropType = {};

const NFTSectionEcosystem = (props: PropType) => {
    const { appState } = useAppContext();

    return (
        <div id="nft-ecosystem" className="nft-section nft-ecosystem">
            <div className="container">
                <div className="row title-row">
                    <div className="col-12">
                        <div className="subtitle centered">Introducing a revolutionary</div>
                        <h2 className="title centered">Knowledge Ecosystem</h2>
                        <p className="description centered padded">
                            Our crypto-powered ecosystem unlocks a whole new way for educators and
                            students around the world to exchange knowledge safely and easily,
                            positioning EduBits as the de facto currency of this new knowledge
                            economy.
                        </p>
                    </div>
                </div>
                <div className="circle-row">
                    <div className="circle-img"></div>
                </div>
                <div className="row items-row">
                    <div className="col-xl-6 col-lg-6 col-md-12 items-col col-left">
                        <div className="box-left">
                            <div className="item item1">
                                <div className="item-icon">
                                    <img src={globe} alt="" />
                                </div>
                                <div className="item-wrapper">
                                    <h3>The New Knowledge Economy</h3>
                                    <p className="item-description">
                                        EduBits will reward content creators for publishing
                                        educational materials, which can then be purchased with
                                        EduBits.
                                    </p>
                                </div>
                            </div>
                            <div className="item item2">
                                <div className="item-icon">
                                    <img src={logoWhite} alt="" />
                                </div>
                                <div className="item-wrapper">
                                    <h3>EduCode Academy</h3>
                                    <p className="item-description">
                                        We aim to expand the curriculum to include other school
                                        subjects, like mathematics, biology, science, and more.
                                    </p>
                                </div>
                            </div>
                            <div className="item item3">
                                <div className="item-icon">
                                    <img src={convertCard} alt="" />
                                </div>
                                <div className="item-wrapper">
                                    <h3>A Currency for Education</h3>
                                    <p className="item-description">
                                        We will create interoperable software libraries that will
                                        establish EduBits as the new currency for education and
                                        facilitate transactions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 items-col col-right">
                        <div className="box-right">
                            <div className="item item1">
                                <div className="item-icon">
                                    <img src={tabletCoins} alt="" />
                                </div>
                                <div className="item-wrapper">
                                    <h3>Learn-to-Earn</h3>
                                    <p className="item-description">
                                        EduBits give you the incentive to learn everything you ever
                                        wanted to learn, inside or outside a classroom.
                                    </p>
                                </div>
                            </div>
                            <div className="item item2">
                                <div className="item-icon">
                                    <img src={handHolding} alt="" />
                                </div>
                                <div className="item-wrapper">
                                    <h3>Scholarships Pool</h3>
                                    <p className="item-description">
                                        EduCode's scholarships pool will be used to fund access to
                                        EduCode Academy and to our partner offerings.
                                    </p>
                                </div>
                            </div>
                            <div className="item item3">
                                <div className="item-icon">
                                    <img src={wallet} alt="" />
                                </div>
                                <div className="item-wrapper">
                                    <h3>EduBits App</h3>
                                    <p className="item-description">
                                        Earn money while browsing the Web, watching videos and
                                        reading e-books or articles. Make your time count!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NFTSectionEcosystem;
