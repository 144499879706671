import React from 'react';

import Accordion from 'react-bootstrap/Accordion';

import FAQEntry from './FAQEntry';

type PropType = {};

const SectionFAQEcosystem = (props: PropType) => {
    return (
        <Accordion className="row" defaultActiveKey="1">
            <div className="col-lg-6 mt_30">
                <FAQEntry eventKey={1} title="Is Bitcoin useful for illegal activities?">
                    Bitcoin is money, and money has always been used both for legal and illegal
                    purposes. Cash, credit cards and current banking systems widely surpass Bitcoin.
                </FAQEntry>

                <FAQEntry eventKey={2} title="Can Bitcoin be regulated?">
                    Bitcoin is money, and money has always been used both for legal and illegal
                    purposes. Cash, credit cards and current banking systems widely surpass Bitcoin.
                </FAQEntry>

                <FAQEntry eventKey={3} title="What about Bitcoin and taxes?">
                    Bitcoin is money, and money has always been used both for legal and illegal
                    purposes. Cash, credit cards and current banking systems widely surpass Bitcoin.
                </FAQEntry>

                <FAQEntry eventKey={4} title="What about Bitcoin and taxes?">
                    Bitcoin is money, and money has always been used both for legal and illegal
                    purposes. Cash, credit cards and current banking systems widely surpass Bitcoin.
                </FAQEntry>
            </div>

            <div className="col-lg-6 mt_30">
                <FAQEntry eventKey={5} title="Is Bitcoin legal?">
                    Bitcoin is money, and money has always been used both for legal and illegal
                    purposes. Cash, credit cards and current banking systems widely surpass Bitcoin.
                </FAQEntry>

                <FAQEntry eventKey={6} title="What about Bitcoin and taxes?">
                    Bitcoin is money, and money has always been used both for legal and illegal
                    purposes. Cash, credit cards and current banking systems widely surpass Bitcoin.
                </FAQEntry>

                <FAQEntry eventKey={7} title="Is Bitcoin useful for illegal activities?">
                    Bitcoin is money, and money has always been used both for legal and illegal
                    purposes. Cash, credit cards and current banking systems widely surpass Bitcoin.
                </FAQEntry>
            </div>
        </Accordion>
    );
};

export default SectionFAQEcosystem;
