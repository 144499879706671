import React from 'react';

import nb from '../assets/partners/nb.png';
import nrc from '../assets/partners/nrc.png';
import acoa from '../assets/partners/acoa.png';

type PropType = {};

const SectionPartners = (props: PropType) => {
    return (
        <div id="partners" className="partners-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Partners</h2>
                            <p>
                                EduCode Canada Inc. received financial and/or advisory support from
                                our amazing partners.
                            </p>
                        </div>
                    </div>
                    <div className="partner-list row no-gutters mt_30">
                        <div className="partner col-xs-12 col-md-4">
                            <img src={nb} alt="img" />
                        </div>
                        <div className="partner col-xs-12 col-md-4">
                            <img src={nrc} alt="img" />
                        </div>
                        <div className="partner col-xs-12 col-md-4">
                            <img src={acoa} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionPartners;
