import React from 'react';

import SaleTimer from './SaleTimer';
import SaleProgress from './SaleProgress';

import binanceLogo from '../assets/hero/binance.svg';

import appStore from '../assets/hero/app-store.svg';
import googlePlay from '../assets/hero/google-play-badge.png';

const DEBUG = false;

const BASE_URL = DEBUG ? 'http://localhost:3000/edx/' : 'https://api.educode.org/edx/';

type PropType = {};

const Hero = (props: PropType) => {
    return (
        <div id="hero" className="hero-area d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mt_30">
                        <div className="hero-image"></div>
                    </div>

                    <div className="col-lg-6 mt_30">
                        <div className="hero-text">
                            <h2>First Web3 LEARN-TO-EARN App</h2>
                            <p>
                                Stop giving away your time and attention for free. EduBits is the
                                first Learn-to-Earn app on the blockchain that turns your time into
                                cash.
                            </p>

                            <SaleTimer
                                header="Token presale ends in:"
                                variant="edubits"
                                until={new Date('2023-06-08T08:06:00')}
                            />

                            <div className="home-range-area" style={{ display: 'none' }}>
                                <SaleProgress />
                            </div>
                            <div className="home-icon-area" style={{ display: 'none' }}>
                                <img src={binanceLogo} alt="Binance" />
                                <div>Token sale ends June 30, 2023</div>
                                <a
                                    href="https://www.binance.com/en/buy-bnb"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Click here to purchase BNB
                                </a>
                            </div>

                            <div className="hero-button-bar">
                                <div className="spacer-mobile" />

                                <a
                                    className="button hero-btn"
                                    href="https://www.pinksale.finance/launchpad/0x19175460BaDE6A4b72dC546BE15A786Ab3B1ecd7?chain=ETH"
                                    // href="https://pancakeswap.finance/swap?outputCurrency=0xCD4557BF92B4D92be53561878E989A9C772f071A"
                                    target="_blank"
                                    rel="noreferrer"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    // }}
                                >
                                    Buy
                                </a>

                                <a
                                    className="button hero-btn"
                                    href={`${BASE_URL}documents/edx-whitepaper?__t=${Date.now()}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    // }}
                                >
                                    White Paper
                                </a>

                                <div className="spacer" />

                                <img className="hero-badge app-store" src={appStore} />

                                <img className="hero-badge google-play" src={googlePlay} />

                                <div className="coming-soon">Coming soon</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
