import React from 'react';

type PropType = {};

const SectionSchools = (props: PropType) => {
    return (
        <div id="schools" className="schools-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Enrolled Schools</h2>
                            <p>
                                The following schools have been using EduCode Academy for their
                                class curriculum delivery.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt_30">
                    <div className="school-list col-xs-12 col-md-6">
                        <div className="school">
                            Beijing Wisdom Science &amp; Technology Co. Ltd.
                            <span className="location">Beijing, China</span>
                        </div>
                        <div className="school">
                            Daegu International School
                            <span className="location">South Korea</span>
                        </div>
                        <div className="school">
                            Heartland Elementary &amp; Indian Valley Schools
                            <span className="location">Overland Park, KS USA</span>
                        </div>
                        <div className="school">
                            Heartland Community School
                            <span className="location">Hampton, NE USA</span>
                        </div>
                        <div className="school">
                            Heritage Christian Academy
                            <span className="location">Calgary, AB Canada</span>
                        </div>
                        <div className="school">
                            Jonathan Edwards Classical Academy
                            <span className="location">Whites Creek, TN USA</span>
                        </div>
                        <div className="school">
                            Fredericton Christian Academy
                            <span className="location">Fredericton, NB Canada</span>
                        </div>
                        <div className="school">
                            Rio Claro West Secondary School
                            <span className="location">Rio Claro, Trinidad and Tobago</span>
                        </div>
                        <div className="school">
                            Glen Ullin Public School
                            <span className="location">Glen Ullin, ND USA</span>
                        </div>
                        <div className="school">
                            Hebron Public School
                            <span className="location">Hebron, ND USA</span>
                        </div>
                        <div className="school">
                            St. Gregory Catholic School
                            <span className="location">Toronto, ON Canada</span>
                        </div>
                    </div>
                    <div className="school-list col-xs-12 col-md-6">
                        <div className="school">
                            Holy Trinity School
                            <span className="location">Richmond Hill, ON Canada</span>
                        </div>
                        <div className="school">
                            T.R. Smedberg Middle School
                            <span className="location">Sacramento, CA USA</span>
                        </div>
                        <div className="school">
                            All Saints Academy
                            <span className="location">Parsippany, NY USA</span>
                        </div>
                        <div className="school">
                            Sandburg Middle School
                            <span className="location">Glendora, CA USA</span>
                        </div>
                        <div className="school">
                            Millennium Middle School
                            <span className="location">South Lyon, MI USA</span>
                        </div>
                        <div className="school">
                            Wilson C. Riles Middle School
                            <span className="location">Roseville, CA USA</span>
                        </div>
                        <div className="school">
                            Grand Forks Secondary School
                            <span className="location">Grand Forks, BC Canada</span>
                        </div>
                        <div className="school">
                            Wilmette Junior High School
                            <span className="location">Wilmette, IL USA</span>
                        </div>
                        <div className="school">
                            Abilene Christian University
                            <span className="location">Abilene, TX USA</span>
                        </div>
                        <div className="school">
                            School District Five of Lexington &amp; Richland Counties
                            <span className="location">SC USA</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionSchools;
