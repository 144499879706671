import React from 'react';

import useAppContext from '../hooks/useAppContext';

import logo from '../assets/header/logo-white.svg';

import facebook from '../assets/nft/facebook-f.svg';
import instagram from '../assets/nft/instagram.svg';
import linkedin from '../assets/nft/linkedin-in.svg';
import twitter from '../assets/nft/twitter.svg';
import youtube from '../assets/nft/youtube.svg';

import bscscan from '../assets/nft/bscscan.svg';
import etherscan from '../assets/nft/etherscan.svg';
import discord from '../assets/nft/discord.svg';
import telegram from '../assets/nft/telegram.svg';
import dextools from '../assets/nft/dextools.svg';
import uniswap from '../assets/nft/uniswap.svg';

import SubscriptionForm from './SubscriptionForm';

type PropType = {};

const BASE_URL = 'https://api.educode.org/edx/';

const NFTFooter = (props: PropType) => {
    const { appState } = useAppContext();

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="logo-wrapper">
                            <img src={logo} alt="img" />
                            <span className="brand">{appState.tokenName}</span>
                        </div>

                        <p className="description spaced-out">
                            $TOT is the reward token of the upcoming EduBits mobile app, and is
                            associated with the NFT collection of the same name on{' '}
                            <a
                                href="https://opensea.io/collection/edubits-thoth-club"
                                target="_blank"
                                rel="noreferrer"
                            >
                                OpenSea
                            </a>
                            .
                        </p>

                        <div className="footer-icon">
                            <a
                                href="https://twitter.com/EduCode_Meta"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={twitter} alt="Twitter" />
                            </a>
                            <a
                                href="https://t.me/edubitscommunity"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={telegram} alt="Telegram" />
                            </a>
                            {/* <a
                                href="#"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={dextools} alt="Dextools" />
                            </a>
                            <a
                                href="#"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={uniswap} alt="Uniswap" />
                            </a> */}
                            <a
                                href="https://etherscan.io/token/0xa232c1125602D44e2d51D6352b9a6B5e010f52c2"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={etherscan} alt="EtherScan" />
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-7 offset-lg-1">
                        <div className="row">
                            <div className="col-lg-6 quick-links">
                                <h3>Quick Links</h3>

                                <div className="links">
                                    <a href="#nft-about">About</a>
                                    {/* <a
                                        href="https://medium.com/@Educode"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Blog
                                    </a> */}
                                    <a
                                        href={`${BASE_URL}documents/whitepaper?__t=${Date.now()}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        White Paper
                                    </a>
                                    {/* <a
                                        href="https://www.pinksale.finance/launchpad/0x19175460BaDE6A4b72dC546BE15A786Ab3B1ecd7?chain=ETH"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Buy
                                    </a> */}
                                </div>
                            </div>
                            <div className="col-lg-6 subscribe">
                                <h3>Subscribe</h3>

                                <SubscriptionForm
                                    btnClass="btn btn-primary btn-lg"
                                    modalClass="nft-modal"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="nft-copyright">
                    Copyright © 2023 EduCode Canada Inc. All rights reserved.
                </div>
            </div>
        </footer>
    );
};

export default NFTFooter;
