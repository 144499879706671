import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';

type PropType = {
    className: string;
    activeSection: string | null;
    onClick?: () => void;
};

const HeaderMenu = (props: PropType) => {
    const { className, activeSection, onClick } = props;

    return (
        <ul className={className}>
            <li>
                <a
                    className={cx({ 'nav-link': true, 'active': activeSection === 'nft-about' })}
                    href="#nft-about"
                    onClick={onClick}
                >
                    About
                </a>
            </li>
            <li>
                <a
                    className={cx({ 'nav-link': true, 'active': activeSection === 'nft-roadmap' })}
                    href="#nft-roadmap"
                    onClick={onClick}
                >
                    Roadmap
                </a>
            </li>
            <li>
                <a
                    className={cx({ 'nav-link': true, 'active': activeSection === 'nft-sale' })}
                    href="#nft-sale"
                    onClick={onClick}
                >
                    Sale
                </a>
            </li>
            <li>
                <a
                    className={cx({
                        'nav-link': true,
                        'active': activeSection === 'nft-edubits-app',
                    })}
                    href="#nft-edubits-app"
                    onClick={onClick}
                >
                    EduBits App
                </a>
            </li>
            <li>
                <a
                    className={cx({ 'nav-link': true, 'active': activeSection === 'nft-team' })}
                    href="#nft-team"
                    onClick={onClick}
                >
                    Team
                </a>
            </li>
            <li>
                <a
                    className={cx({ 'nav-link': true, 'active': activeSection === 'nft-faq' })}
                    href="#nft-faq"
                    onClick={onClick}
                >
                    Tokenomics
                </a>
            </li>
            {/* <li>
                <a
                    className="nav-link"
                    href="https://medium.com/@Educode"
                    target="_blank"
                    rel="noreferrer"
                >
                    Blog
                </a>
            </li> */}
            {/* <li>
                <a
                    className="btn btn-primary btn-md buy-btn"
                    href="https://www.pinksale.finance/launchpad/0x19175460BaDE6A4b72dC546BE15A786Ab3B1ecd7?chain=ETH"
                    target="_blank"
                    rel="noreferrer"
                >
                    Buy
                </a>
            </li> */}
        </ul>
    );
};

export default HeaderMenu;
