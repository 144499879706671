import React, { useCallback, useRef, useState } from 'react';

import { Formik, Field, ErrorMessage, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';

import ReCAPTCHA from 'react-google-recaptcha';

import { sendEmail, SendEmailRequest } from '../lib/apiLibrary';

import AlertModal from './AlertModal';
import LoadingIndicator from './LoadingIndicator';

import calling from '../assets/contact/calling.svg';
import message from '../assets/contact/message.svg';
import telegram from '../assets/contact/telegram.svg';

// decorative only
import shape1 from '../assets/contact/shape1.svg';
import shape2 from '../assets/contact/shape2.svg';
import shape4 from '../assets/contact/shape4.svg';
import shape6 from '../assets/contact/shape6.svg';
import shape9 from '../assets/contact/shape9.svg';
import shape11 from '../assets/contact/shape11.svg';

type FormValues = {
    fullName: string;
    emailAddress: string;
    message: string;
};

type PropType = {};

const SectionContact = (props: PropType) => {
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const validationSchema = yup.object().shape({
        fullName: yup.string().trim('Enter your full name').required('Enter your full name'),
        emailAddress: yup
            .string()
            .email('Enter a valid email address')
            .required('Enter your email address'),
        message: yup.string().trim('Enter your message').required('Enter your message'),
    });

    const handleModalClose = useCallback(() => {
        setShowSuccessModal(false);
    }, []);

    const recaptchaRef = useRef<ReCAPTCHA | null>(null);

    const handleFormSubmit = useCallback(
        async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
            console.warn('SUBMITTING CONTACT FORM: ', values);
            setSubmitError(null);
            try {
                // validate the captcha
                const recaptcha = recaptchaRef.current;
                if (!recaptcha) throw new Error('Captcha not inited');
                // executeAsync() will never terminate if the user dismisses the captcha window
                // see: https://github.com/dozoisch/react-google-recaptcha/issues/198
                // let's set a timeout and reset the form if expired
                const timeout = window.setTimeout(() => {
                    setSubmitting(false);
                }, 10 * 1000);
                const token = await recaptcha.executeAsync();
                window.clearTimeout(timeout); // user completed challenge successfully
                console.warn('CAPTCHA TOKEN: ', token);
                recaptcha.reset(); // prepare for next use -- TODO: might not immediately be available afterwards
                if (!token) throw new Error('Could not validate captcha');
                // submit
                const payload: SendEmailRequest = {
                    ...values,
                    token: token,
                };
                await sendEmail(payload);
                resetForm(); // when successful only
                console.warn('SUCCESS!');
                setShowSuccessModal(true);
            } catch (err) {
                console.error('FAILED TO SUBMIT FORM: ', err);
                setSubmitError('Your message could not be sent. Please try again later.');
            } finally {
                setSubmitting(false);
            }
        },
        []
    );

    return (
        <div id="contact" className="contact-area">
            <AlertModal show={showSuccessModal} title="Message Sent" handleClose={handleModalClose}>
                We received your message. We will get in touch with you shortly.
            </AlertModal>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Get In Touch!</h2>
                            <p>
                                Reach out to us by filling out the form below. We'll get in touch as
                                soon as possible.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-10 mt_30">
                        <div className="contact-option-wrapper">
                            <div className="item">
                                <div className="icon-area">
                                    <img src={message} alt="img" />
                                </div>
                                <a href="mailto:info@edubits.io">info@edubits.io</a>
                            </div>
                            <div className="item mt">
                                <div className="icon-area">
                                    <img src={calling} alt="img" />
                                </div>
                                <a href="tel:+18668422575">1-866-842-2575</a>
                            </div>
                            <div className="item mt">
                                <div className="icon-area">
                                    <img src={telegram} alt="img" />
                                </div>
                                <a href="https://t.me/educodex" target="_blank" rel="noreferrer">
                                    Telegram: educodex
                                </a>
                                {/* Discord: educode#9762 */}
                            </div>
                        </div>
                        <Formik<FormValues>
                            initialValues={{
                                fullName: '',
                                emailAddress: '',
                                message: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        size="invisible"
                                        sitekey="6LfcGPEdAAAAAHaFCbA87Ne1vm5_XeBw_CO9sOq4"
                                        theme="dark"
                                        onChange={(...args) => {
                                            console.warn('CAPTCHA ONCHANGE: ', args);
                                        }}
                                        // onExpired={(...args) => {
                                        //     console.warn('CAPTCHA ONEXPIRED: ', args);
                                        // }}
                                        // onErrored={(...args) => {
                                        //     console.error('CAPTCHA ONERROR: ', args);
                                        // }}
                                    />
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 mt_30 field-first">
                                            <div className="input_group">
                                                <Field
                                                    id="fullName"
                                                    name="fullName"
                                                    placeholder="Full name"
                                                    disabled={isSubmitting}
                                                />
                                                <ErrorMessage name="fullName">
                                                    {(msg) => (
                                                        <div className="error-message">{msg}</div>
                                                    )}
                                                </ErrorMessage>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-12 mt_30">
                                            <div className="input_group">
                                                <Field
                                                    id="emailAddress"
                                                    name="emailAddress"
                                                    placeholder="Email address"
                                                    disabled={isSubmitting}
                                                />
                                                <ErrorMessage name="emailAddress">
                                                    {(msg) => (
                                                        <div className="error-message">{msg}</div>
                                                    )}
                                                </ErrorMessage>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 mt_30">
                                            <div className="input_group">
                                                <Field
                                                    id="message"
                                                    name="message"
                                                    as="textarea"
                                                    placeholder="Message"
                                                    disabled={isSubmitting}
                                                />
                                                <ErrorMessage name="message">
                                                    {(msg) => (
                                                        <div className="error-message">{msg}</div>
                                                    )}
                                                </ErrorMessage>
                                            </div>
                                            <div className="contact-btn mt_40">
                                                <button
                                                    className="button"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    Send Message
                                                    {!!isSubmitting && <LoadingIndicator />}
                                                </button>
                                                {!!submitError && (
                                                    <div className="error-message">
                                                        {submitError}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contact-shape-box">
                                        <div className="shape shape1">
                                            <img src={shape1} alt="img" />
                                        </div>
                                        <div className="shape shape2">
                                            <img src={shape1} alt="img" />
                                        </div>
                                        <div className="shape shape3">
                                            <img src={shape2} alt="img" />
                                        </div>
                                        <div className="shape shape4">
                                            <img src={shape4} alt="img" />
                                        </div>
                                        <div className="shape shape6">
                                            <img src={shape6} alt="img" />
                                        </div>
                                        <div className="shape shape8">
                                            <img src={shape1} alt="img" />
                                        </div>
                                        <div className="shape shape9">
                                            <img src={shape9} alt="img" />
                                        </div>
                                        <div className="shape shape10">
                                            <img src={shape4} alt="img" />
                                        </div>
                                        <div className="shape shape11">
                                            <img src={shape11} alt="img" />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionContact;
