import React, { useCallback, useState } from 'react';

import { Formik, Field, ErrorMessage, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { registerMailingList, RegisterMailingListRequest } from '../lib/apiLibrary';

import AlertModal from './AlertModal';
import LoadingIndicator from './LoadingIndicator';

type FormValues = {
    emailAddress: string;
};

type PropType = {
    btnClass?: string;
    modalClass?: string;
};

const SubscriptionForm = (props: PropType) => {
    const { btnClass, modalClass } = props;

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const validationSchema = yup.object().shape({
        emailAddress: yup
            .string()
            .email('Please enter a valid email address')
            .required('Please enter a valid email address'),
    });

    const handleModalClose = useCallback(() => {
        setShowSuccessModal(false);
    }, []);

    const handleFormSubmit = useCallback(
        async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
            console.warn('SUBMITTING MAILING LIST REGISTRATION FORM: ', values);
            setSubmitError(null);
            try {
                // submit
                const payload: RegisterMailingListRequest = {
                    ...values,
                    listKey: 'edx-mailing-list',
                };
                await registerMailingList(payload);
                resetForm(); // when successful only
                console.warn('SUCCESS!');
                setShowSuccessModal(true);
            } catch (err) {
                console.error('FAILED TO SUBMIT FORM: ', err);
                setSubmitError('Something went wrong. Please try again later.'); // the backend never fails
            } finally {
                setSubmitting(false);
            }
        },
        []
    );

    return (
        <>
            <AlertModal
                className={modalClass}
                show={showSuccessModal}
                title="Subscription Successful"
                handleClose={handleModalClose}
            >
                Thank you for subscribing to our mailing list!
            </AlertModal>

            <Formik<FormValues>
                initialValues={{
                    emailAddress: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="sub-box">
                            <div className="input-box">
                                <Field
                                    id="emailAddress"
                                    name="emailAddress"
                                    placeholder="Enter your email address"
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage name="emailAddress">
                                    {(msg) => <div className="error-message">{msg}</div>}
                                </ErrorMessage>
                            </div>
                            <div className="btn-sub">
                                <button
                                    className={btnClass || 'button'}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Subscribe
                                    {!!isSubmitting && <LoadingIndicator />}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default SubscriptionForm;
