import React from 'react';

import Slider from 'react-slick';

import useAppContext from '../hooks/useAppContext';

import statusUp from '../assets/advantages/status-up.svg';
import handsHelping from '../assets/advantages/hands-helping-solid.svg';
import shield from '../assets/advantages/shield.svg';
import book from '../assets/advantages/book.svg';
import tv from '../assets/advantages/tv.svg';
import wallet from '../assets/advantages/wallet.svg';

import carousel1 from '../assets/advantages/carousel/image-1.png';
import carousel2 from '../assets/advantages/carousel/image-2.png';
import carousel3 from '../assets/advantages/carousel/image-3.png';
import carousel4 from '../assets/advantages/carousel/image-4.png';

type PropType = {};

const SectionAdvantages = (props: PropType) => {
    const { appState } = useAppContext();

    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 575.98,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div id="advantages" className="token-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2 className="keep-case">EduBits app</h2>
                            <p>
                                How many hours of video have you watched online, while marketers and
                                big corporations raked in the profits? As they say, time is money.
                                Time to make it count with the EduBits app.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6 mt_30">
                        <div className="token-box">
                            <div className="icon-box">
                                <div className="img-area img-status-up">
                                    <img src={statusUp} alt="img" />
                                </div>
                            </div>
                            <h3>Learn-to-Earn</h3>
                            <p>
                                EduBits give you the incentive to learn everything you ever wanted
                                to learn, inside or outside a classroom.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6 mt_30">
                        <div className="token-box">
                            <div className="icon-box">
                                <div className="img-area img-book">
                                    <img src={book} alt="img" />
                                </div>
                            </div>
                            <h3>Read and Earn</h3>
                            <p>
                                Read a book, a magazine or the news on the Web and be rewarded in
                                cash for your time spent.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6 mt_30">
                        <div className="token-box">
                            <div className="icon-box">
                                <div className="img-area img-tv">
                                    <img src={tv} alt="img" />
                                </div>
                            </div>
                            <h3>Watch and Earn</h3>
                            <p>
                                Get cash rewards for watching educational videos from various video
                                providers on the Web.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6 mt_30">
                        <div className="token-box">
                            <div className="icon-box">
                                <div className="img-area img-wallet">
                                    <img src={wallet} alt="img" />
                                </div>
                            </div>
                            <h3>Exchangeable</h3>
                            <p>
                                The tokens earned can be easily exchanged for other cryptocurrencies
                                or fiat currencies.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 app-carousel mt_100">
                        <Slider {...carouselSettings}>
                            <div className="carousel-image-wrapper">
                                <img className="carousel-image" src={carousel1} alt="" />
                            </div>
                            <div className="carousel-image-wrapper">
                                <img className="carousel-image" src={carousel2} alt="" />
                            </div>
                            <div className="carousel-image-wrapper">
                                <img className="carousel-image" src={carousel3} alt="" />
                            </div>
                            <div className="carousel-image-wrapper">
                                <img className="carousel-image" src={carousel4} alt="" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionAdvantages;
