import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';

type PropType = {
    className: string;
    onClick?: () => void;
};

const HeaderMenu = (props: PropType) => {
    const { className, onClick } = props;

    return (
        <ul className={className}>
            {/* <li>
                <a
                    className="nav-link"
                    href="#features"
                    onClick={onClick}
                >
                    About
                </a>
            </li> */}
            <li>
                <a
                    className="nav-link"
                    href="#advantages"
                    onClick={onClick}
                >
                    EduBits App
                </a>
            </li>
            <li>
                <a
                    className="nav-link"
                    href="#allocation"
                    onClick={onClick}
                >
                    Tokenomics
                </a>
            </li>
            <li>
                <a
                    className="nav-link"
                    href="#roadmap"
                    onClick={onClick}
                >
                    Roadmap
                </a>
            </li>
            <li>
                <a
                    className="nav-link"
                    href="#documents"
                    onClick={onClick}
                >
                    Documents
                </a>
            </li>
            <li>
                <a
                    className="nav-link"
                    href="#team"
                    onClick={onClick}
                >
                    Team
                </a>
            </li>
            <li>
                <a
                    className="nav-link"
                    href="#contact"
                    onClick={onClick}
                >
                    Contact
                </a>
            </li>
            <li>
                <a
                    className="nav-link"
                    href="https://medium.com/@Educode"
                    target="_blank"
                    rel="noreferrer"
                >
                    Blog
                </a>
            </li>
        </ul>
    );
};

export default HeaderMenu;
