import React from 'react';

import SaleTimer from './SaleTimer';

import saleImage from '../assets/nft/sale.png';

type PropType = {};

const NFTSectionSale = (props: PropType) => {
    return (
        <div id="nft-sale" className="nft-section nft-sale">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 content-col">
                        <div className="subtitle">Token sale</div>
                        <h2 className="title">How to Buy</h2>

                        <p className="description">
                            By acquiring Thoth tokens, you not only become part of an educational
                            revolution, but also gain potential benefits from the growth and success
                            of the project. Join the Thoth community today and help us shape a
                            future where education knows no boundaries. Follow these four steps to
                            become a proud owner of Thoth:
                        </p>

                        <SaleTimer
                            header="Token presale ends in:"
                            variant="thoth"
                            until={new Date('2023-06-08T08:06:00')}
                        />

                        {/* STEP 1 */}
                        <div className="step">
                            <div className="step-title">Set up a digital wallet</div>
                            <p className="description">
                                Ensure you have a compatible digital wallet to store your Thoth
                                tokens securely.
                            </p>
                        </div>
                        {/* STEP 2 */}
                        <div className="step">
                            <div className="step-title">Find a trusted exchange</div>
                            <p className="description">
                                Visit a reliable cryptocurrency exchange that supports Thoth.
                                {/* Some popular exchanges include [Exchange 1], [Exchange 2], and [Exchange 3]. */}
                            </p>
                        </div>
                        {/* STEP 3 */}
                        <div className="step">
                            <div className="step-title">Purchase Thoth</div>
                            <p className="description">
                                On the chosen exchange, follow the instructions to buy Thoth using
                                your preferred cryptocurrency or fiat currency.
                            </p>
                        </div>
                        {/* STEP 4 */}
                        <div className="step">
                            <div className="step-title">Store Thoth safely</div>
                            <p className="description">
                                Once purchased, transfer your Thoth tokens to your digital wallet
                                for safekeeping. Make sure to keep your private keys secure and
                                backed up.
                            </p>
                        </div>
                        {/* <SaleTimer until={new Date('2022-05-01')} /> */}
                    </div>

                    <div className="col-lg-4 image-col">
                        <div className="image-wrapper">
                            <img src={saleImage} alt="" />
                        </div>
                    </div>
                </div>

                {/* <div className="buy-now">
                    <a className="btn btn-primary btn-lg" href="#">
                        Buy Something
                    </a>
                </div> */}
            </div>
        </div>
    );
};

export default NFTSectionSale;
