import React from 'react';

import rocketIcon from '../assets/nft/roadmap-rocket.svg';
import transactionIcon from '../assets/nft/roadmap-transaction.svg';
import schoolIcon from '../assets/nft/roadmap-school.svg';

type PropType = {};

const NFTSectionRoadmap = (props: PropType) => {
    return (
        <div id="nft-roadmap" className="nft-section nft-roadmap">
            <div className="container">
                <div className="subtitle centered">Timeline</div>
                <h2 className="title centered">$TOT Roadmap</h2>
                <p className="description centered">
                    Thoth's journey is filled with exciting milestones and developments. Here's a
                    glimpse into the roadmap ahead:
                </p>

                <div className="roadmap-wrapper">
                    <div className="roadmap-entry">
                        <div className="entry-wrapper">
                            <div className="entry-block">
                                <div className="entry-text">
                                    <div className="entry-title">$TOT ICO and Token Launch</div>
                                    <p className="description">
                                        Engage the community and offer an opportunity to support the
                                        project's vision. Launch the Thoth token and establish a
                                        strong foundation for growth.
                                    </p>
                                </div>
                                <div className="entry-icon">
                                    <img src={rocketIcon} alt="" />
                                    {/* <div className="entry-label">Phase 1</div> */}
                                </div>
                            </div>

                            <div className="entry-dot" />
                            <div className="entry-line" />
                        </div>
                        <div className="roadmap-break" />
                    </div>

                    <div className="roadmap-entry">
                        <div className="entry-wrapper">
                            <div className="entry-block">
                                <div className="entry-text">
                                    <div className="entry-title">EduBits Mobile Application</div>
                                    <p className="description">
                                        Launch EduBits, the Learn-to-Earn platform that
                                        revolutionizes the educational experience. Reward users with
                                        cash incentives for their learning achievements.
                                    </p>
                                </div>
                                <div className="entry-icon">
                                    <img src={schoolIcon} alt="" />
                                    {/* <div className="entry-label">Phase 2</div> */}
                                </div>
                            </div>

                            <div className="entry-dot" />
                            <div className="entry-line" />
                        </div>
                        <div className="roadmap-break" />
                    </div>

                    <div className="roadmap-entry">
                        <div className="entry-wrapper">
                            <div className="entry-block">
                                <div className="entry-text">
                                    <div className="entry-title">New Educational Horizons</div>
                                    <p className="description">
                                        Expand the curriculum to include other school subjects.
                                        Foster a holistic learning environment where users can
                                        explore diverse fields of knowledge.
                                    </p>
                                </div>
                                <div className="entry-icon">
                                    <img src={transactionIcon} alt="" />
                                    {/* <div className="entry-label">Phase 3</div> */}
                                </div>
                            </div>

                            <div className="entry-dot" />
                            <div className="entry-line" />
                        </div>
                        <div className="roadmap-break" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NFTSectionRoadmap;
