import React from 'react';

import Accordion from 'react-bootstrap/Accordion';

import FAQEntry from './FAQEntry';

import faqImage from '../assets/nft/faq.jpg';

type PropType = {};

const NFTSectionFAQ = (props: PropType) => {
    return (
        <div id="nft-faq" className="nft-section nft-faq">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 content-col">
                        <div className="subtitle">$TOT</div>
                        <h2 className="title">Tokenomics</h2>
                        <p className="description">
                            Thoth is not just any ordinary memecoin. It carries the torch of
                            knowledge and a vision for a brighter future. Here are the key details
                            of Thoth's tokenomics:
                        </p>
                        <dl>
                            <dt>Token Name</dt>
                            <dd>Thoth</dd>

                            <dt>Symbol</dt>
                            <dd>$TOT</dd>

                            <dt>Total Supply</dt>
                            <dd>50,000,000,000</dd>

                            <dt>Presale/Liquidity</dt>
                            <dd>35,000,000,000</dd>

                            <dt>CEX Listing</dt>
                            <dd>10,000,000,000</dd>

                            <dt>Airdrop</dt>
                            <dd>2,000,000,000</dd>

                            <dt>Token Rewards (EduBits)</dt>
                            <dd>2,000,000,000</dd>

                            <dt>Marketing</dt>
                            <dd>1,000,000,000</dd>
                        </dl>
                        {/* <p className="description">
                            91.7% of the tokens were sent to the liquidity pool, LP tokens were
                            burnt, and contract is renounced. The remaining 8.3% of the supply is
                            being held in a multi-sig wallet only to be used as tokens for future
                            centralized exchange listings, and liquidity pools.
                        </p> */}
                        <p className="disclaimer">
                            $TOT is a memecoin with no intrinsic value or expectation of financial
                            return. There is no formal team or roadmap. This token is completely
                            useless and for entertainment purposes only.
                        </p>
                        {/* 
                        <Accordion className="row" defaultActiveKey="1">
                            <FAQEntry
                                eventKey={1}
                                title="Is Bitcoin useful for illegal activities?"
                            >
                                Bitcoin is money, and money has always been used both for legal and
                                illegal purposes. Cash, credit cards and current banking systems
                                widely surpass Bitcoin.
                            </FAQEntry>

                            <FAQEntry eventKey={2} title="Can Bitcoin be regulated?">
                                Bitcoin is money, and money has always been used both for legal and
                                illegal purposes. Cash, credit cards and current banking systems
                                widely surpass Bitcoin.
                            </FAQEntry>

                            <FAQEntry eventKey={3} title="What about Bitcoin and taxes?">
                                Bitcoin is money, and money has always been used both for legal and
                                illegal purposes. Cash, credit cards and current banking systems
                                widely surpass Bitcoin.
                            </FAQEntry>

                            <FAQEntry eventKey={4} title="What about Bitcoin and taxes?">
                                Bitcoin is money, and money has always been used both for legal and
                                illegal purposes. Cash, credit cards and current banking systems
                                widely surpass Bitcoin.
                            </FAQEntry>
                        </Accordion> */}
                    </div>

                    <div className="col-lg-4 static-image">
                        <img src={faqImage} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NFTSectionFAQ;
