import React, { useEffect, useState } from 'react';

import useAppContext from '../hooks/useAppContext';

import dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

type PropType = {
    header: String;
    variant: 'edubits' | 'thoth'; // to prevent styles from bleeding over
    until: Date;
};

const SaleTimer = (props: PropType) => {
    const { header, variant, until } = props;
    const { appState } = useAppContext();

    const [timeValues, setTimeValues] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const [expired, setExpired] = useState(true);

    useEffect(() => {
        const initRemaining = dayjs(until).diff(dayjs());

        let interval: number | null = null;

        if (initRemaining > 0) {
            interval = window.setInterval(() => {
                const diff = dayjs(until).diff(dayjs());

                const remaining = dayjs.duration(diff);

                setTimeValues({
                    days: Math.floor(remaining.asDays()),
                    hours: remaining.hours(),
                    minutes: remaining.minutes(),
                    seconds: remaining.seconds(),
                });
            }, 500);
            setExpired(false);
        } else {
            setExpired(true);
        }

        return () => {
            if (interval !== null) {
                window.clearInterval(interval);
            }
        };
    }, [until]);

    return expired ? null : (
        <div className={variant === 'thoth' ? 'nft-sale-timer' : 'sale-timer'}>
            <div className="timer-title">{header}</div>
            <div className="timer">
                <div className="days">
                    {timeValues.days.toString().padStart(2, '0')} <span>Days</span>
                </div>
                <div className="hours">
                    {timeValues.hours.toString().padStart(2, '0')} <span>Hours</span>
                </div>
                <div className="minutes">
                    {timeValues.minutes.toString().padStart(2, '0')} <span>Minutes</span>
                </div>
                <div className="seconds">
                    {timeValues.seconds.toString().padStart(2, '0')} <span>Seconds</span>
                </div>
            </div>
        </div>
    );
};

export default SaleTimer;
