import React from 'react';

import aboutImage from '../assets/nft/about.png';

import aboutIcon1 from '../assets/nft/about-icon1.svg';
import aboutIcon2 from '../assets/nft/about-icon2.svg';
import aboutIcon3 from '../assets/nft/about-icon3.svg';
import aboutIcon4 from '../assets/nft/about-icon4.svg';

type PropType = {};

const NFTSectionAbout = (props: PropType) => {
    return (
        <div id="nft-about" className="nft-section nft-about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 image-col">
                        <div className="image-wrapper">
                            <img src={aboutImage} alt="" />
                        </div>
                    </div>

                    <div className="col-lg-8 content-col">
                        <div className="subtitle">About</div>
                        <h2 className="title">What Are Thoth Tokens?</h2>
                        <p className="description white">
                            In the vast world of cryptocurrencies, a new player has emerged,
                            blending the realms of education, technology, and finance. Thoth,
                            revered in ancient Egypt as the god of knowledge and science, will now
                            have an impact in the digital age, by becoming the first popular
                            memecoin with a primary focus on education.
                        </p>
                        <div className="subtitle">A Comprehensive Educational Platform</div>
                        <p className="description white">
                            Thoth relies in part on the established{' '}
                            <a href="https://educode.org/" target="_blank" rel="noreferrer">
                                EduCode Academy
                            </a>{' '}
                            learning platform, which has been successfully teaching computer science
                            to thousands of children worldwide since 2016. Through engaging animated
                            videos, captivating educational games and self-led tutorials, children
                            have discovered the joys of coding and problem-solving, nurturing their
                            curiosity and paving the way for a new generation of innovators. Thoth's
                            ambition extends beyond computer science, aiming to soon become a
                            comprehensive educational platform covering various subjects, like
                            mathematics, biology, science, and more.
                        </p>
                        <div className="subtitle">
                            Learn-to-Earn: Empowering Education Through Rewards
                        </div>
                        <p className="description white">
                            $TOT is the reward token of the upcoming{' '}
                            {/* <a href="https://edubits.io/" target="_blank" rel="noreferrer">
                                EduBits
                            </a>{' '} */}
                            <a href="#nft-edubits-app">EduBits</a> mobile app, which introduces the
                            pivotal concept of "learn-to-earn" to the world of education, by
                            combining educational content with a unique blockchain-based reward
                            system. The EduBits app gives children and adults the opportunity to
                            earn cash rewards for their learning achievements. The $TOT token is
                            being launched as a memecoin prior to the launch of the EduBits app
                            during summer 2023.
                        </p>
                    </div>
                </div>

                <section className="about-more">
                    <div className="title centered">Join the Revolution!</div>
                    <p className="description centered padded">
                        By holding Thoth tokens, you become an active participant in our mission to
                        revolutionize education and empower learners all around the world. Join us
                        on this incredible journey and be a part of the Thoth revolution!
                    </p>
                    <div className="row about-blurbs">
                        <div className="col-xl-3 col-sm-6 col-xs-12 about-blurb">
                            <div className="about-icon">
                                <img src={aboutIcon1} alt="" />
                            </div>
                            <div className="about-description">
                                Interact with this memecoin to have a tremendous impact on education
                                all over the world
                            </div>
                        </div>

                        <div className="col-xl-3 col-sm-6 col-xs-12 about-blurb">
                            <div className="about-icon">
                                <img src={aboutIcon2} alt="" />
                            </div>
                            <div className="about-description">
                                Each holder will automatically get 1&nbsp;NFT during the pre-sale
                                when requested via{' '}
                                <a
                                    href="https://t.me/edubitscommunity"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Telegram
                                </a>
                            </div>
                        </div>

                        <div className="col-xl-3 col-sm-6 col-xs-12 about-blurb">
                            <div className="about-icon">
                                <img src={aboutIcon3} alt="" />
                            </div>
                            <div className="about-description">
                                Thoth token is launched with no presale, zero taxes, LP burnt and
                                contract renounced
                            </div>
                        </div>

                        <div className="col-xl-3 col-sm-6 col-xs-12 about-blurb">
                            <div className="about-icon">
                                <img src={aboutIcon4} alt="" />
                            </div>
                            <div className="about-description">
                                The $TOT token is associated with the NFT collection of the same
                                name on{' '}
                                <a
                                    href="https://opensea.io/collection/edubits-thoth-club"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    OpenSea
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default NFTSectionAbout;
