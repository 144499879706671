import React from 'react';

import Accordion from 'react-bootstrap/Accordion';

import FAQEntry from './FAQEntry';

type PropType = {};

const SectionFAQGeneral = (props: PropType) => {
    return (
        <Accordion className="row" defaultActiveKey="1">
            <div className="col-lg-6 mt_30">
                <FAQEntry eventKey={1} title="What are EduBits?">
                    EduBits is the first cryptocurrency created to empower a new era of education.
                </FAQEntry>

                <FAQEntry eventKey={5} title="When will the EduBits launch?">
                    The EduBits token sale starts on June 7, 2023.
                </FAQEntry>

                <FAQEntry
                    eventKey={3}
                    title="Can I trade EduBits tokens for other cryptocurrencies and fiat currencies?"
                >
                    Yes. EduBits can be exchanged for other cryptocurrencies and for fiat
                    currencies.
                </FAQEntry>

                <FAQEntry eventKey={4} title="Can I earn EduBits by learning?">
                    Yes. EduBits are provided as incentives to students when they complete
                    achievements in EduCode Academy and via our partner offerings.
                </FAQEntry>
            </div>

            <div className="col-lg-6 mt_30">
                <FAQEntry eventKey={2} title="What is EduCode Academy?">
                    EduCode Academy is a gamified online learning platform which provides a series
                    of Computer Science courses for K-12 students around the world.
                </FAQEntry>

                <FAQEntry eventKey={6} title="What is the scholarships pool?">
                    The scholarships pool will be used to fund the costs of student access to
                    EduCode Academy and to our partner offerings.
                </FAQEntry>

                <FAQEntry eventKey={7} title="What is the EduBits Ecosystem?">
                    The EduBits Ecosystem is a suite of interoperable software libraries that will
                    establish EduBits as the new currency for education and facilitate transactions
                    between consumers and providers of EduBits.
                </FAQEntry>
            </div>
        </Accordion>
    );
};

export default SectionFAQGeneral;
