import React from 'react';

import danyan from '../assets/team/danyan-luo.jpg';
import erich from '../assets/team/eric-hervet.jpg';
import erics from '../assets/team/eric-snow.jpg';
import guyverson from '../assets/team/guyverson-vernous.jpg';
import myrtha from '../assets/team/myrtha-vernous-murat.jpg';
import mignesh from '../assets/team/mignesh-parekh.jpg';

import facebook from '../assets/team/facebook-f.svg';
import linkedin from '../assets/team/linkedin-in.svg';
import twitter from '../assets/team/twitter.svg';

type PropType = {
    memberKey: string;
};

const TeamMember = (props: PropType) => {
    const { memberKey } = props;

    const image = {
        'danyan-luo': danyan,
        'eric-hervet': erich,
        'eric-snow': erics,
        'guyverson-vernous': guyverson,
        'myrtha-vernous-murat': myrtha,
        'mignesh-parekh': mignesh,
    }[memberKey];

    const name = {
        'danyan-luo': 'Dr. Danyan Luo',
        'eric-hervet': 'Dr. Éric Hervet',
        'eric-snow': 'Eric Snow',
        'guyverson-vernous': 'Guyverson Vernous',
        'myrtha-vernous-murat': 'Myrtha Vernous-Murat',
        'mignesh-parekh': 'Mignesh Parekh',
    }[memberKey];

    const title = {
        'danyan-luo': 'Chief Technology Officer',
        'eric-hervet': 'Chief Academic Advisor',
        'eric-snow': 'Director of Operations',
        'guyverson-vernous': ' Founder and Board Chair',
        'myrtha-vernous-murat': 'Chief Financial Officer',
        'mignesh-parekh': 'Chief Executive Officer',
    }[memberKey];

    const facebookLink = {
        'danyan-luo': undefined,
        'eric-hervet': undefined,
        'eric-snow': undefined,
        'guyverson-vernous': undefined,
        'myrtha-vernous-murat': undefined,
        'mignesh-parekh': undefined,
    }[memberKey];

    const twitterLink = {
        'danyan-luo': undefined,
        'eric-hervet': undefined,
        'eric-snow': undefined,
        'guyverson-vernous': undefined,
        'myrtha-vernous-murat': undefined,
        'mignesh-parekh': undefined,
    }[memberKey];

    const linkedInLink = {
        'danyan-luo': 'https://ca.linkedin.com/in/danyan-luo-337874230',
        'eric-hervet': 'https://www.linkedin.com/in/eric-hervet-01023a121',
        'eric-snow': undefined,
        'guyverson-vernous': 'https://www.linkedin.com/in/guyverson',
        'myrtha-vernous-murat': 'https://www.linkedin.com/in/myrtha-vernous-murat-10b415158',
        'mignesh-parekh': 'https://www.linkedin.com/in/mignesh-parekh-0200b222',
    }[memberKey];

    return (
        <div className="team-card liftable-card">
            <div className="img-area">
                <img className="img-fluid" src={image} alt="img" />
            </div>
            <div className="info-area">
                <h3>{name}</h3>
                <p>{title}</p>
                <div className="team-icon">
                    {!!facebookLink && (
                        <a href={facebookLink} target="_blank" rel="noreferrer">
                            <img src={facebook} alt="Facebook" />
                        </a>
                    )}
                    {!!twitterLink && (
                        <a href={twitterLink} target="_blank" rel="noreferrer">
                            <img src={twitter} alt="Twitter" />
                        </a>
                    )}
                    {!!linkedInLink && (
                        <a href={linkedInLink} target="_blank" rel="noreferrer">
                            <img src={linkedin} alt="LinkedIn" />
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TeamMember;
