import React, { useContext } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';

type PropType = {
    eventKey: number;
    title: React.ReactChild;
    children: React.ReactChild;
};

const FAQEntry = (props: PropType) => {
    const { eventKey, title, children } = props;

    const currentEventKey = useContext(AccordionContext);

    const stringKey = eventKey.toString();

    return (
        <Card className={currentEventKey === stringKey ? 'toggled' : undefined}>
            <Accordion.Toggle as={Card.Header} eventKey={stringKey}>
                {title}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={stringKey}>
                <Card.Body>{children}</Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

export default FAQEntry;
