import React from 'react';

import facebook from '../assets/footer/facebook-f.svg';
import instagram from '../assets/footer/instagram.svg';
import linkedin from '../assets/footer/linkedin-in.svg';
import twitter from '../assets/footer/twitter.svg';
import youtube from '../assets/footer/youtube.svg';
import SubscriptionForm from './SubscriptionForm';

type PropType = {};

const Footer = (props: PropType) => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer-box text-center">
                            <h2>Don't miss out!</h2>
                            <SubscriptionForm />
                            <p>
                                Subscribe to our mailing list to have the latest news about our
                                <br /> projects delivered directly to your inbox
                            </p>
                            <div className="footer-icon">
                                <a
                                    href="https://facebook.com/EduCodeAcademy/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={facebook} alt="Facebook" />
                                </a>
                                <a
                                    href="https://twitter.com/EduCode_Meta"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={twitter} alt="Twitter" />
                                </a>
                                <a
                                    href="https://www.instagram.com/educodeacademy/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={instagram} alt="Instagram" />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/educode-canada"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={linkedin} alt="LinkedIn" />
                                </a>
                                <a
                                    href="https://www.youtube.com/channel/UCf2pbQDq6h6aIu-MONDyh6A"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={youtube} alt="YouTube" />
                                </a>
                            </div>
                        </div>
                        <p className="copyright">
                            Copyright © 2023 EduCode Canada Inc. All rights reserved.
                            <br />
                            Exchange rates to US dollars are provided by{' '}
                            <a href="https://coinmarketcap.com/" target="_blank" rel="noreferrer">
                                CoinMarketCoin.com
                            </a>{' '}
                            and may differ at the time of purchase.
                            <br /> Illustration{' '}
                            <a
                                href="https://www.dreamstime.com/online-education-isometric-vector-illustration-online-education-isometric-color-vector-illustration-e-class-m-learning-infographic-image173581466"
                                target="_blank"
                                rel="noreferrer"
                            >
                                173581466
                            </a>{' '}
                            ©{' '}
                            <a
                                href="https://www.dreamstime.com/natalia1891991_info"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Natalia Nesterenko
                            </a>{' '}
                            |{' '}
                            <a href="https://www.dreamstime.com/" target="_blank" rel="noreferrer">
                                Dreamstime.com
                            </a>
                            <br/>
                            App Store and the Apple logo are trademarks of Apple Inc.
                            <br/>
                            Google Play and the Google Play logo are trademarks of Google LLC.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
