import React from 'react';

import documentWhitepaper from '../assets/documents/document-whitepaper.svg';
import documentTwoPager from '../assets/documents/document-2pager.svg';
import documentBooklet from '../assets/documents/document-booklet.svg';
import documentPR from '../assets/documents/document-pr.svg';

import download from '../assets/documents/download.svg';

const DEBUG = false;

const BASE_URL = DEBUG ? 'http://localhost:3000/edx/' : 'https://api.educode.org/edx/';

type PropType = {};

const SectionDocuments = (props: PropType) => {
    return (
        <div id="documents" className="document-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Documents</h2>
                            <p>
                                Download the EduBits white paper and learn more about our gamified
                                online learning platform.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 mt_30">
                        <div className="document-card">
                            <img className="img-fluid" src={documentWhitepaper} alt="img" />
                            <div className="text-box">
                                <h3>EduBits White Paper</h3>
                                <p>-2023-</p>
                                <a
                                    href={`${BASE_URL}documents/edx-whitepaper?__t=${Date.now()}`}
                                    className="download-box"
                                >
                                    <img src={download} alt="img" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 mt_30">
                        <div className="document-card">
                            <img className="img-fluid" src={documentTwoPager} alt="img" />
                            <div className="text-box">
                                <h3>EduBits Two-Pager</h3>
                                <p>-2022-</p>
                                <a
                                    href={`${BASE_URL}documents/2pager?__t=${Date.now()}`}
                                    className="download-box"
                                >
                                    <img src={download} alt="img" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 mt_30">
                        <div className="document-card">
                            <img className="img-fluid" src={documentPR} alt="img" />
                            <div className="text-box">
                                <h3>EduBits Press Release</h3>
                                <p>-2022-</p>
                                <a
                                    href={`${BASE_URL}documents/pr?__t=${Date.now()}`}
                                    className="download-box"
                                >
                                    <img src={download} alt="img" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 mt_30">
                        <div className="document-card">
                            <img className="img-fluid" src={documentBooklet} alt="img" />
                            <div className="text-box">
                                <h3>EduCode Academy Booklet</h3>
                                <p>-2020-</p>
                                <a
                                    href={`${BASE_URL}documents/booklet?__t=${Date.now()}`}
                                    className="download-box"
                                >
                                    <img src={download} alt="img" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionDocuments;
